import React, {useEffect} from "react";
import { useMediaQuery, Box, Grid, Typography, Button } from "@mui/material";
import CustomWidgetForHeader from "../../components/WebComponents/CustomTextForHeader";
import CustomWidgetForContent from "../../components/WebComponents/CustomTextForContent";
import ContentInLeftImageRightWeb from "../../components/WebComponents/ContentInLeftImageRightWeb";
import CustomWidgetForMob from "../../components/MobComponents/CustomTextForMob";
import odpoStandsImage from "../../assets/Background/AboutUs/odpoStands.png";
import transparentImage from "../../assets/Background/AboutUs/transparent.png";
import trustAndSaftyImageImage from "../../assets/Background/AboutUs/trust_and_safety.png";
import flexibilityAndFreedomImage from "../../assets/Background/AboutUs/flexibility_and_freedom.png";
import specialltForYouImage from "../../assets/Background/AboutUs/speciallt_for_you.png";
import connectWithUs from "../../assets/Background/AboutUs/connect_with_us.png";
import IMAGE_RIGHT_TEXT from "./components/Image_right_Text";

import ContentTopImageBelowMob from "../../components/MobComponents/ContentTopImageBelow";
const titleText = {
  title: "About us",
  description:
    "At Odpo, we are changing how people reserve and use vehicles for their travel needs. Instead of merely renting cars, we connect car owners with adventurous travelers. We're building a community that loves exploring, making connections, and sharing experiences. Join us on this exciting journey and be a part of a movement that's revolutionizing travel.",
};
const TransparentComAssets = {
  image: transparentImage,
  title: "Transparent Communication",
  subtitle:
    "We keep our guests informed at every step, ensuring a sense of confidence throughout their journey",
};
const TrustAndSaftyAssets = {
  image: trustAndSaftyImageImage,
  title: "Trust and Safety",
  subtitle:
    "We instill trust and peace of mind, allowing guests to focus on enjoying their travels",
};
const FlexFreedomAssets = {
  image: flexibilityAndFreedomImage,
  title: "Flexibility and Freedom",
  subtitle:
    "Flexible pick-up & drop-off options, along with adaptable itineraries, allow guests to explore",
};
const SpeciallyForYouAssets = {
  image: specialltForYouImage,
  title: "Specially for You",
  subtitle:
    "From a diverse range of vehicle options with a variety of price alternatives, we ensure that each guest feels special",
};

const ConnectWitUS = {
  title: "Connect with Us",
  description:
    "If you're curious about how Odpo ensures a seamless, secure experience for hosts and guests, or if you have innovative ideas to enhance our platform, we invite you to connect with us. Your inputs can make a huge difference in shaping the future of Odpo. Join us in being a part of our community and sharing your valuable insights.",
};

const AboutUs = () => {
  const isMobileView = useMediaQuery("(max-width: 767px)");
  const customePadding = isMobileView ? "2%" : "8%";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <div style={{ padding: "4%" }}>
      <div
        id="Web_How_Odpo_works"
        style={{
          paddingLeft: customePadding,
          paddingRight: customePadding,
        }}
      >
        {isMobileView?<CustomWidgetForMob
        title={titleText.title}
        titileFont="2.5rem"
        subtitle1={titleText.description}
    
        paragraphAline={true}
        
        alignText="start"
        />:<CustomWidgetForHeader
          title={titleText.title}
          subtitle1={titleText.description}
        />}
      </div>
      <div id="Web_Left_Text_right_image">

        {isMobileView?
       <ContentTopImageBelowMob
       title={"What Odpo stand for"}
       subtitle1={" At Odpo, our core mission is clear and powerful: we aim to transform the way people travel by providing a platform that enables unique and personalised car-sharing experiences. Our belief is simple yet profound – travel should transcend mere transportation; it should be a means to connect, explore, and forge enduring memories."}
   imgSource={odpoStandsImage}/>  :
   <ContentInLeftImageRightWeb
       title={"What Odpo stand for"}
       subtitle1={" At Odpo, our core mission is clear and powerful: we aim to transform the way people travel by providing a platform that enables unique and personalised car-sharing experiences. Our belief is simple yet profound – travel should transcend mere transportation; it should be a means to connect, explore, and forge enduring memories."}
   imgSource={odpoStandsImage}/>  
      }

      </div>
      <div id="Web_Left_Text_right_description">
        <Grid
          container
          style={{
            paddingTop: !isMobileView?"7.5%":"5%",
            paddingBottom: !isMobileView?"10.2%":"4%",
            display: "flex",
            alignItems: "start",
            justifyItems: "start",
          }}
        >
          <Grid item xs={12} sm={6}>
            <div style={{ paddingRight: "8%" }}>
              <Typography style={{ paddingBottom: "2.6%", fontSize: isMobileView?"1.5rem":"3rem" }}>
                Exceeding guest expectations at Odpo
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography fontSize={isMobileView?"1rem":"1.5rem"} paragraph>
            Our commitment to exceptional hospitality goes beyond the ordinary. We believe in providing our guests with an extraordinary experience that encapsulates the spirit of exploration and adventure. Treating guests the Odpo way means ensuring a seamless journey from the moment they book their unique car to the hassle-free drop-off at the station.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
          style={{
            paddingTop: !isMobileView?"7.5%":"3%",
           paddingBottom: !isMobileView?"10.3%":"4%",
           
          }}
            container
            rowSpacing={8}
            columnSpacing={{ xs: 2, sm: 4, md: 6 }}
          >
            <Grid item xs={6} md={6}>
              <IMAGE_RIGHT_TEXT
                images={TransparentComAssets.image}
                title={TransparentComAssets.title}
                subtitle={TransparentComAssets.subtitle}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <IMAGE_RIGHT_TEXT
                images={TrustAndSaftyAssets.image}
                title={TrustAndSaftyAssets.title}
                subtitle={TrustAndSaftyAssets.subtitle}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <IMAGE_RIGHT_TEXT
                images={FlexFreedomAssets.image}
                title={FlexFreedomAssets.title}
                subtitle={FlexFreedomAssets.subtitle}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <IMAGE_RIGHT_TEXT
                images={SpeciallyForYouAssets.image}
                title={SpeciallyForYouAssets.title}
                subtitle={SpeciallyForYouAssets.subtitle}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        {isMobileView ? (
          <div
            id={"became_host_mobile"}
            style={{
              padding: "1%",
              paddingTop: "10%",
              paddingBottom: "9%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={connectWithUs}
              alt={"not loading"}
              loading="lazy"
              style={{ width: "100%", height: "auto" }}
            />
            <div style={{ paddingLeft: "1%" }}>
              <CustomWidgetForMob
                title={ConnectWitUS.title}
                subtitle1={ConnectWitUS.description}
              />
              <div style={{ paddingTop: "3%" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#12229F",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#12229F",
                    },
                  }}
                >
                  CONNECT
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            id={"became_host_web"}
            style={{  paddingTop:"10.3%",  paddingBottom: !isMobileView?"9%":"8%",display: "flex", alignItems: "center" }}
          >
            <img
              src={connectWithUs}
              alt={"not loading"}
              loading="lazy"
              style={{
                width: "50%",
                "@media (max-width: 300px)": { width: "100%" },
              }}
            />
            <div style={{ paddingLeft: "4%" }}>
              <div
                style={{
                  width: "100%",
                  padding: "1rem",
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <CustomWidgetForContent
                title={ConnectWitUS.title}
                subtitle1={ConnectWitUS.description}
              />
              </div>
              <div style={{ paddingTop:"4%", paddingBottom: "2.5%" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#12229F",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#D92429",
                    },
                  }}
                >
                 CONNECT
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
