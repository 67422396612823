import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Managing Your Vehicle",
};

const defaultTheme = createTheme();

const HostManagingYourVehicle = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Hosting standards and tips*/}
        <section id="HostingStandardsAndTips">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Hosting standards and tips" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
              Being a great host means more than just providing a vehicle. Follow these simple tips to enhance your hosting experience:
                <ul>
                  <li>
                  Regularly clean and maintain your vehicle to make a good impression.
                  </li>
                  <li>
                  Be clear about your vehicle's features and rules. Respond promptly to messages.
                  </li>

                 <li>
                 Describe your vehicle accurately in your listing. Honesty builds trust.
                    </li>

                    <li>Offer flexible booking options to accommodate different guests' needs.      </li>      
                    <li>
                    Keep your vehicle safe and follow all safety guidelines.
                    </li>
                    <li>
                    Resolve guest concerns promptly and professionally.  
                    </li>
                    <li>
                    Be available and helpful to your guests at every step.  
                    </li>
                </ul>
                Follow these tips to create a positive experience for your guests and become a trusted host in our community.
              </p>
            </Typography>
          </Box>
        </section>

        {/*Change in license plate number*/}
        <section id="ChangeLicenseNumber">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Change in license plate number" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
              If you need to update a vehicle's license plate number, you can do so when the vehicle has undergone a "vehicle re-registration" or "license plate replacement." To change the license plate number, follow these steps:
                <ol type="1" >
                  <li>
                  Start by locating the vehicle in the Inventory section. This is where all your vehicle records are stored and managed.
                  </li>
                  <li>
                  Once you've found the vehicle, access its detailed information by selecting it.
                  </li>
                  <li>
                  In the "Basic Information" section of the vehicle details, you'll see the current license plate number. Modify this field with the new license plate number you wish to assign to the vehicle.
                  </li>
                  <li>
                  Don't forget to save your changes after updating the license plate number. Look for the "Save" button usually positioned at the bottom or top of the details page.
                  </li>
                </ol>
                It's important to note that the option to change the license plate number is typically available only if the vehicle has undergone re-registration or received a new license plate. Double-check the accuracy of the new license plate number before saving the changes.
              </p>
            </Typography>
          </Box>
        </section>


          {/*Uploading Annual Maintenance Report*/}
          <section id="UploadingAnnualMaintenanceReport">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Uploading Annual Maintenance Report" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
            <p>
            To maintain vehicle listings “Available” status on the platform, hosts are required to upload an annual maintenance report. This report is mandatory for vehicles that are 2 years old or older from the present year. Vehicles younger than 2 years do not need to upload this report.
            </p>
            <p>
                <b>
                Steps to Upload Annual Maintenance Report:
                </b>
                <ol type="1">
                    <li>
                    Navigate to the "Reservations" tab in your account.
                    </li>
                    <li>
                    Select the vehicle for which you need to upload the report.
                    </li>
                    <li>
                    Find and click on the "Annual Maintenance" option.
                    </li>
                    <li>
                    On the next screen, click on the "Upload New Inspection Form" button.  
                    </li>
                    <li>
                    You will be navigated to the upload screen. There are two scenarios:
                    <ol type="a">
                        <li>
                        If you are uploading the form for the first time, download the form from the page and have it updated by a certified mechanic. Once updated, upload it on the same page using the upload option and click on the "Confirm and Submit" button.
                        </li>
                        <li>
                        If you already have the updated form, simply upload it and click on the "Confirm and Submit" button.
                        </li>
                    </ol>
                    </li>
                    <li>
                    Once uploaded, our team will verify the report and update the vehicle status from "Need Update" to "Available".
                    </li>
                </ol>
                Please ensure to upload the annual maintenance report promptly to avoid any disruptions in your vehicle listing.
            </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostManagingYourVehicle;
