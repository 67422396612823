import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Quick Start",
};

const defaultTheme = createTheme();

const HostQuickStart = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Quick Start Guide */}
        <section id="QuickStartGuide">
          { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text=" Quick Start Guide" />
            <Typography sx={{ fontSize:isMobile?"body2":"1.5rem"}}>
              <p>
              This quick start guide will help you seamlessly set up your
              account and start managing your fleet. Follow these steps to get
              started:
              <ol type='1'>
                <li>
                  Begin by signing up for an account on our platform. Visit the
                  account page and click on the "Sign Up" option.
                </li>
                <li>
                  Provide all the necessary details as requested. Once your
                  signup is successful, use your credentials to log in.{" "}
                </li>

                <li>
                  Choose the appropriate account type based on your affiliation:
                  <ul>
                    <li>Company: Register on behalf of an organization.</li>
                    <li>Individual: Register as an individual fleet owner.</li>
                  </ul>
                </li>

                <li>
                  Complete your account setup by providing the required details.
                  Make sure to grant the necessary permissions for Camera and
                  Location access.
                </li>

                <li>
                  Under the "Accounts" tab, set up your payout details to ensure
                  smooth transactions. Before adding vehicles, set up essential
                  parameters:
                  <ul>
                    <li>
                      Define your Price Plan to determine vehicle pricing.
                    </li>
                    <li>
                      Create Stations to establish pickup and drop-off
                      locations.
                    </li>
                  </ul>
                </li>

                <li>
                  Now, you can add vehicles to your inventory:
                  <ul>
                    <li>
                      <b>Manually: &nbsp;</b> Enter vehicle details manually.
                    </li>
                    <li>
                      <b>Integration via OEM: &nbsp;</b>Automate data retrieval from
                      your company's website for added convenience.
                    </li>
                  </ul>
                </li>

                <li>
                  You can manage Host Partner through the "Employee" tab. Add
                  Host Partner and assign their roles as needed.
                </li>

                <li>
                  Stay organized by monitoring vehicle reservations in the
                  "Reservation" tab.
                </li>
              </ol>
              </p> 
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostQuickStart;
