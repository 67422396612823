import React from "react";
import {
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";

function FlexibleImageCard({
  imageUrl,
  title,
  description,
  CardHeight,
  CardMaxWidth,
  CardWidth,
  ImageHeight,
}) {
  const defaultCardHeight = '30vh';
  const defaultCardMaxWidth = '42vh';
  const defaultCardWidth = '120vh';
  const defaultImageHeight = '110vh';

  return (
    <div style={{ padding: "1%" }}>
      <Card
        sx={{
          height: CardHeight || defaultCardHeight,
          maxWidth: CardMaxWidth || defaultCardMaxWidth,
          width: CardWidth || defaultCardWidth,
          backgroundColor: "#F6F6F6",
          borderRadius: "1.8vh",
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            image={imageUrl}
            alt="green iguana"
            height={ImageHeight||defaultImageHeight}
          />
          <CardContent sx={{ borderRadius: "2.6%", overflowY: "auto" }}>
            <Typography fontSize="1.2rem" fontWeight="600" component="div">
              {title}
            </Typography>
            <Typography sx={{paddingTop:"1vh"}}fontSize="1.2rem" color="text.secondary" paragraph>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default FlexibleImageCard;
