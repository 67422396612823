import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Paper, Box, Grid, Typography, useMediaQuery } from '@mui/material';

import mobileBackgroundImage from '../../../assets/Background/homescreen/mobile_background_car.png'; 
import webBackgroundImage from '../../../assets/Background/homescreen/web_background_car.png';

function MainFeaturedPost(props) {
  const { post } = props;
  const isMobileView = useMediaQuery('(max-width: 767px)');

  const backgroundImage = isMobileView ? mobileBackgroundImage : webBackgroundImage;
  const imageHeight = isMobileView ? '90vh' : '80vh';

  return (
    <div id="Main_the_texts_is_overlay_on_the_image">
      <Paper
        style={{
          alignItems: 'flex-start',
          color: '#fff',
          overflow: 'hidden',
          height: `${imageHeight}`,
         
          margin: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundColor: 'grey.800',
          filter: `${isMobileView ? 'brightness(100%)' : 'brightness(100%)'}`,
          boxShadow: 'none',
        }}
      >
        {/* Increase the priority of the car background image */}
        {<img style={{ display: 'none' }} src={backgroundImage} alt={post.imageText} />}
        <Box
          style={{
            alignItems: 'flex-start',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item xs={12} >
            <Box
              style={{
                alignItems: 'flex-start',
                padding: isMobileView ? '2rem' : '3rem',
                textAlign: isMobileView ? 'center' : 'left',
              }}
            >
              <Typography
                component={isMobileView ? 'h3' : 'h2'}
                variant={isMobileView ? 'h4' : 'h2'}
                letterSpacing={isMobileView ? '3px' : '8px'}
                sx={{ fontWeight: '600' }}
                color="inherit"
              >
                {post.title}
              </Typography>
              <Typography
                component={isMobileView ? 'h3' : 'h1'}
                variant={isMobileView ? 'h3' : 'h1'}
                letterSpacing={isMobileView ? '3px' : '8px'}
                color="inherit"
                sx={{ mb: 1, fontWeight: '600' }}
              >
                {post.subtitle}
              </Typography>
              <Typography variant={isMobileView ? 'body2' : 'h5'} letterSpacing="1px" color="inherit" paragraph  sx={{ mb: 3, width:`${isMobileView?'100%':"50%"}`}}>
                {post.description}
              </Typography>
      
              <Stack spacing={isMobileView ? 2 : 4} direction={isMobileView ? 'column' : 'row'} alignItems={isMobileView ? 'center' : 'flex-start'}>
                <Button
                  variant="contained"
                  sx={{
                    height: isMobileView ? '7vh' : 'auto',
                    color: 'white',
                    fontSize: isMobileView ? '1rem' : '1.25rem',
                    backgroundColor: '#D92429',
                    textTransform: 'capitalize',
                    borderRadius: 2,
                    '&:hover': {
                      backgroundColor: '#D92429',
                    },
                  }}
                >
                  Download Drive App
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: isMobileView ? '7vh' : 'auto',
                    color: 'white',
                    fontSize: isMobileView ? '1rem' : '1.25rem',
                   // fontWeight: 500,
                    textTransform: 'capitalize',
                    backgroundColor: '#12229F',
                    borderRadius: 2,
                    '&:hover': {
                      borderColor: '#12229F',
                    },
                  }}
                >
                  Download Host App
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;