import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Reservation Payment",
};

const defaultTheme = createTheme();

const ReservationPayment = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
    
        {/* Reservation Cost */}
        <section id="ReservationCost">
          <Box mt={4} mb={2}>
          { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}

            <ContentTitleWithLine text="Reservation Cost" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  Reservation costs for our vehicle rental services are
                  influenced by various factors. These include the vehicle type
                  you choose, your selected insurance plan, any extra hours or
                  miles added to the reservation, and a reservation fee.
                </li>
                <li>
                  For a detailed breakdown of these cost components, please
                  visit our Reservation Cost page.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>

        {/* Reservation Receipt */}
        <section id="ReservationReceipt">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Reservation Receipt" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                Detailed breakdown of a reservation receipt will be added after
                reservation end in the application. To find the reservation
                receipt, please follow the steps below:
              </p>
              <ol type="1">
                <li>Open your “Reservation tab”.</li>
                <li>Find and open the reservation you want receipt for.</li>
                <li>
                  Click on reservation, scroll down to find & select “View
                  reservation Charges”.
                </li>
                <li>
                  Upon clicking, you’ll be navigated to “Reservation Charges”
                  screen to view receipt.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/* Downloading Reservation Invoice */}
        <section id="ReservationInvoice">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Downloading Reservation Invoice" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                To download an invoice for a reservation, follow these steps:
              </p>
              <ol type="1" style={{ lineHeight: 2 }}>
                <li>Navigate to the "Reservations" tab in your account.</li>
                <li>Select the reservation for which you need the invoice.</li>
                <li>
                  Click on "Reservation Charges" to view the charges associated
                  with the reservation.
                </li>
                <li>
                  In the "Reservation Charges" screen, locate the option in the
                  top right corner <SaveAltIcon /> and click on it.
                </li>
                <li>
                  The invoice PDF will be opened. Click on the three dots{" "}
                  <MoreVertIcon /> in the PDF viewer.
                </li>
                <li>
                  Select the "Download" option to download the invoice to your
                  device.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/* Payment method - Cards */}
        <section id="PaymentMethodCards">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Payment method - Cards" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                For now, reservation payments are only accepted via Cards. Cards
                can be added at the time of reservation or it can be added under
                cards section in Account Details Tab.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default ReservationPayment;
