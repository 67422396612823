import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Modifying Or Canceling Your Reservation",
};

const defaultTheme = createTheme();

const ModifyingCancelingReservation = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Cancel a reservation */}
        <section id="CancelReservation">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Cancel a reservation" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>To cancel a reservation, follow these steps:</p>
              <ol type="1" style={{ lineHeight: 2 }}>
                <li>
                  Open the reservation you wish to cancel from “Reservations”
                  Tab.
                </li>
                <li>Scroll to the bottom of the reservation details.</li>
                <li>
                  Click on "Cancel Reservation". This will take you to the
                  "Cancel Reservation" page.
                </li>
                <li>Click "Submit" to confirm the cancellation.</li>
              </ol>
              <p>
                You will receive a notification confirming the cancellation.
              </p>
              <p>
                For complete Cancellation policy, please visit our Cancellation
                Policy.
              </p>
            </Typography>
          </Box>
        </section>
        {/* Changing Reservation Duration */}
        <section id="ChangingReservationDuration">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Changing Reservation duration" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                Once your vehicle is reserved and you need to extend the
                reservation, you need to follow steps as below:
              </p>

              <ol type="1">
                <li>
                  Go to the "Reservations" tab and locate the reservation you
                  wish to extend the duration for.
                </li>
                <li>
                  Open the reservation and find the "Extend Duration" option,
                  located below the "Locate Vehicle" option.
                </li>
                <li>Update the date and time according to your needs.</li>
                <li>Confirm the updated details.</li>
                <li>
                  After confirming, the reservation details will be updated with
                  the corrected information.
                </li>
              </ol>
            </Typography>
            <Typography
              sx={{
                fontSize:isMobile?"0.875rem":"1.2rem",
              }}
            >
              <p>
              (Note: Extending a pre-booked vehicle reservation cannot be
              processed without confirmation from the Host. The final decision
              regarding the extension of a pre-booked vehicle rests solely with
              the Host. If you wish to extend a pre-booked vehicle beyond the
              current reservation, please follow the steps mentioned above and
              communicate with the Host to ensure a smooth process.)
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default ModifyingCancelingReservation;
