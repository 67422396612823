import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  ThemeProvider,
  createTheme,
  Box,
  Tab,
  Tabs,
  Grid,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomWidgetForMob from "../../components/MobComponents/CustomTextForMob";
import CustomWidgetForHeader from "../../components/WebComponents/CustomTextForHeader";

const defaultTheme = createTheme();

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const titleText = {
  title: "Frequently Asked Questions",
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FAQS = () => {
  const [value, setValue] = React.useState(0);
  const [showMore, setShowMore] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const driveData = [
   
    {
      title: "Quick Start",
      items: [
        { text: "Quick Start Guide", route: "/faqs/drive/QuickStartGuide#QuickStartGuide" },
      ],
    },

    {
      title: "Getting Started",
      items: [
        { text: "Account Registration", route: "/faqs/drive/GettingStarted#AccountRegistration" },
        { text: "Code For Verification", route: "/faqs/drive/GettingStarted#CodeVerification" },
        { text: "Change Your Password", route: "/faqs/drive/GettingStarted#ChangePassword" },
        {text: "Changing Name or Phone number",route: "/faqs/drive/GettingStarted#ChangeNamePhone"},
        { text: "Password Reset", route: "/faqs/drive/GettingStarted#PasswordReset"  },
      ],
    },
    {
      title:"Odpo Vehicle Driver Eligibility",
      items:[
        { text: "Age Requirements", route: "/faqs/drive/DriverEligibility#AgeRequirements" },
        { text: "Driving Licence Verification", route: "/faqs/drive/DriverEligibility#LicenceVerification" },
       
      ]
    },
    {
      title: "Vehicle Reservations",
      items: [
        {text:"Vehicle Reservation", route: "/faqs/drive/VehicleReservations#VehicleReservation"},     
        {text:"Existing Reservations", route: "/faqs/drive/VehicleReservations#ExistingReservations"},     
        {text:"What Does The Various Reservation States Mean", route: "/faqs/drive/VehicleReservations#ReservationStates"},     
        {text:"Locating Your Reserved Vehicle", route: "/faqs/drive/VehicleReservations#LocatingYourReserved"},     
        {text:"How Do I Start A Reservation", route: "/faqs/drive/VehicleReservations#StartReservation"},     
        {text:"Extending A Trip", route: "/faqs/drive/VehicleReservations#ExtendingTrip"},     
        {text:"Reporting Incidents", route: "/faqs/drive/VehicleReservations#ReportingIncidents"},  
        {text:"Viewing Insurance Card", route: "/faqs/drive/VehicleReservations#ViewingInsurance"},  
        {text:"Ending A Reservation", route: "/faqs/drive/VehicleReservations#EndingReservation"},       
        {text:"Abandoned", route: "/faqs/drive/VehicleReservations#Abandoned"},      
      ],
    },
    {
      title: "Reservation Payment",
      items: [
        {text: "Reservation Cost", route: "/faqs/drive/ReservationPayment#ReservationCost"},    
        {text:"Reservation Receipt",route: "/faqs/drive/ReservationPayment#ReservationReceipt"},
        {text:"Downloading Reservation Invoice",route: "/faqs/drive/ReservationPayment#ReservationInvoice"}, 
        // {text:"Payment Due", route: "/faqs/drive/ReservationPayment#AccountRegistration"},     
        {text:"Payment Method - Cards", route: "/faqs/drive/ReservationPayment#PaymentMethodCards"},     
        // {text: "Tax", route: "/faqs/drive/ReservationPayment#AccountRegistration"},
      ],
    },
    {
      title: "Modifying or Canceling Your Reservation",
      items: [
        {text: "Cancel a Reservation", route: "/faqs/drive/ModifyingCancelingReservation#CancelReservation"},     
        {text:"Changing Reservation Duration", route: "/faqs/drive/ModifyingCancelingReservation#ChangingReservationDuration"}],
    },
    {
      title: "Driver Responsibilities",
      items: [
        {text: "Prohibited Use", route: "/faqs/drive/DriverResponsibilities#ProhibitedUse"}
      ],
        
         
    },
    // {
    //   title: "Insurance",
    //   items: [
    //     {text: "Insurance", route: "/faqs/drive/AccountSetup#AccountRegistration"}
    //    ],
    // },
  ];

  const hostData = [
   
    {
      title: "Quick Start",
      items: [
        { text: "Quick Start Guide", route: "/faqs/host/HostQuickStart#QuickStartGuide" },
      ],
    },
    {
      title: "Account Management",
      items: [
        { text: "Creating An Account", route: "/faqs/host/HostAccountManagement/#CreatingAnAccount" },
        { text: "Account Setup", route: "/faqs/host/HostAccountManagement#AccountSetup" },
        { text: "Code For Verification", route: "/faqs/host/HostAccountManagement#CodeForVerification" },
        { text: "Change Your Password", route: "/faqs/host/HostAccountManagement#ChangeYourPassword" },
        {text: "Changing Name or Phone number",route: "/faqs/host/HostAccountManagement#ChangingNameOrNumber"},
        { text: "Password Reset", route: "/faqs/host/HostAccountManagement#PasswordReset"  },
      ],
    },
    {
      title: "Host Partners",
      items: [
        { text: "Host Partners", route: "/faqs/host/HostPatners#HostPartner" },
        { text: "Removing Host partners", route: "/faqs/host/HostPatners#RemovingHostpartners" },
      ],
    },
    {
      title: "Getting Started",
      items: [
        { text: "Adding vehicle to inventory", route: "/faqs/host/HostGettingStarted#AddingVehicleInventory" },
        { text: "Adjusting Vehicle Availability", route: "/faqs/host/HostGettingStarted#AdjustingVehicleAvailability" },
        { text: "Vehicle Eligibility", route: "/faqs/host/HostGettingStarted#VehicleEligibility" },
        { text: "Vehicle Categories", route: "/faqs/host/HostGettingStarted#VehicleCategories" },
        { text: "Station Setup", route: "/faqs/host/HostGettingStarted#StationSetup" },
      ],
    },
    {
      title: "Pricing Your Vehicle",
      items: [
        { text: "Creating Price Plans", route: "/faqs/host/HostPricingYourVehicle#CreatingPricePlans" },
        { text: "Editing An Existing Price Plan", route: "/faqs/host/HostPricingYourVehicle#EditingPricePlan" },
      ],
    },
    {
      title: "Settings and Options",
      items: [
        { text: "Viewing Reservations", route: "/faqs/host/HostSettingsAndOptions#ViewingReservations" },
        { text: "Preventing reservation or Deleting vehicle from Inventory", route: "/faqs/host/HostSettingsAndOptions#PreventingOrDeleting" },
      ],
    },
    {
      title: "Payments / Payouts",
      items: [
        { text: "What is a payout", route: "/faqs/host/HostPaymentsAndPayouts#WhatIsAPayout" },
        { text: "Setting up an account to get paid", route: "/faqs/host/HostPaymentsAndPayouts#AccountSetup" },
        { text: "Can I update my payout account", route: "/faqs/host/HostPaymentsAndPayouts#UpdatePayoutAccount" },
        { text: "Viewing your earnings", route: "/faqs/host/HostPaymentsAndPayouts#ViewEarnings" },
        { text: "Handling refund request", route: "/faqs/host/HostPaymentsAndPayouts#HandlingRefundRequest" },
        // { text: "Earnings and host take rate (TBD)", route: "/faqs/host/PaymentsPayouts#EarningsTakeRate" },
        // { text: "Wrong or missing earnings (TBD)", route: "/faqs/host/PaymentsPayouts#MissingEarnings" },
        { text: "Charges for Tolls, Tickets, Refueling, Cleaning, for smoking in vehicle", route: "/faqs/host/HostPaymentsAndPayouts#ExtraCharges" },
        { text: "Damage report policy", route: "/faqs/host/HostPaymentsAndPayouts#DamageReportPolicy" },
      ],
    },
    {
      title: "Managing Reservations",
      items: [
        { text: "Verifying a Guest license", route: "/faqs/host/HostManagingReservations#VerifyingAGuestLicense" },
        { text: "Requests to extend trip", route: "/faqs/host/HostManagingReservations#RequestsToExtendTrip" },
        // { text: "View car sharing agreement (TBD)", route: "/faqs/host/HostManagingBookingsAndTrips#CarSharingAgreement" },
      ],
    },
    {
      title: "Managing Your Vehicle",
      items: [
        { text: "Hosting standards and tips", route: "/faqs/host/HostManagingYourVehicle#HostingStandardsAndTips" },
        { text: "Change in license plate number", route: "/faqs/host/HostManagingYourVehicle#ChangeLicenseNumber" },
        { text: "Uploading Annual Maintenance Report", route: "/faqs/host/HostManagingYourVehicle#UploadingAnnualMaintenanceReport" },
      ],
    },
    {
      title: "Modifying or Canceling Reservations",
      items: [
        { text: "Upgrading Reservation", route: "/faqs/host/HostModifyingOrCancelingTrips#UpgradingReservation" },
        { text: "Trips canceled by guests", route: "/faqs/host/HostModifyingOrCancelingTrips#TripsCanceledByGuests" },
        { text: "Guest Abandone Trip", route: "/faqs/host/HostModifyingOrCancelingTrips#GuestAbandoneTrip" },
        { text: "Host cancelling a reservation", route: "/faqs/host/HostModifyingOrCancelingTrips#HostCancellingAReservation" },
      ],
    },
    {
      title: "Maintaining Your Vehicle",
      items: [
        { text: "Maintenance requirements", route: "/faqs/host/HostMaintainingYourVehicle#MaintenanceRequirements" },
        { text: "Flat tire damage", route: "/faqs/host/HostMaintainingYourVehicle#FlatTireDamage" },
      ],
    },
    {
      title: "Safety Protocols",
      items: [
        { text: "Safety recalls", route: "/faqs/host/HostSafetyProtocols#SafetyRecalls" },
        { text: "Resolving a safety recall", route: "/faqs/host/HostSafetyProtocols#ResolvingSafetyRecall" },
      ],
    },
    {
      title: "Vehicle Policies",
      items: [
        { text: "Vehicle tracking and technology policy", route: "/faqs/host/HostVehiclePolicies#VehicleTrackingAndTechnologyPolicy" },
      ],
    },
    // {
    //   title: "Understanding and choosing protection",
    //   items: [
    //     { text: "Personal insurance requirements (TBD)", route: "/faqs/host/Protection#PersonalInsuranceRequirements" },
    //     { text: "Protection plans – In brief (TBD)", route: "/faqs/host/Protection#ProtectionPlansInBrief" },
    //   ],
    // },
    {
      title: "Managing Vehicle Damage",
      items: [
        // { text: "Claiming a Damage (TBD)", route: "/faqs/host/VehicleDamage#ClaimingDamage" },
        { text: "Requesting payment for additional damage repairs", route: "/faqs/host/HostManagingVehicleDamage#RequestingPayment" },
        { text: "Replacement vehicles if reserved vehicle is late", route: "/faqs/host/HostManagingVehicleDamage#ReplacementVehicles" },
        // { text: "Accidents and your personal insurance (TBD)", route: "/faqs/host/VehicleDamage#AccidentsPersonalInsurance" },
        // { text: "Reporting damage (TBD)", route: "/faqs/host/VehicleDamage#ReportingDamage" },
      ],
    },
    // {
    //   title: "Taxes (TBD)",
    //   items: [],
    // },
  ];

  const isMobile = useMediaQuery("(max-width: 600px)"); 
  const padding = isMobile ? "15px" : "60px";
  return (
    <ThemeProvider theme={defaultTheme}>
      <div style={{ padding }}>
        <div>
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <br></br>
          <Typography  sx={{ fontSize:isMobile?"body2":"1.5rem"}}>
            Everything you need to know about Odpo. Can't find the answer
          </Typography>
          <Typography  sx={{ fontSize:isMobile?"body2":"1.5rem"}}>
            you're looking for? Don't hesitate to contact our support team.
          </Typography>
        </div>
        <br></br>
        <div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Drive" {...a11yProps(0)} />
              <Tab label="Host" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid container spacing={3}>
              {driveData.map((section, sectionIndex) => (
                <Grid item key={sectionIndex} md={4}>
                  <div> 
                    <Typography  sx={{ fontSize:isMobile?"body2":"1.5rem"}}>{section.title}</Typography>
                    <ul>
                    {section.items
                        .slice(0, showMore ? 5 : section.items.length)
                        .map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <Button
                              component={Link}
                              to={item.route}
                              target="_blank"
                              sx={{
                                justifyContent: "start",
                                verticalAlign: "inherit",
                                textAlign: "left",
                              }}
                            >
                              {item.text}
                            </Button>
                          </li>
                        ))}
                    </ul>
          
                    {section.items.length > 5 && (
                      console.log("items",section.items.length ),
              <Button onClick={() => setShowMore(!showMore)}>
                {showMore ? "See More" : "See Less"}
                
              </Button>
            )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid container spacing={3}>
              {hostData.map((section, sectionIndex) => (
                <Grid item key={sectionIndex} md={4}>
                  <div> 
                    <Typography  sx={{ fontSize:isMobile?"body2":"1.5rem"}}>{section.title}</Typography>
                    <ul>
                    {section.items
                        .slice(0, showMore ? 5 : section.items.length)
                        .map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <Button
                              component={Link}
                              to={item.route}
                              target="_blank"
                              sx={{
                                justifyContent: "start",
                                verticalAlign: "inherit",
                                textAlign: "left",
                              }}
                            >
                              {item.text}
                            </Button>
                          </li>
                        ))}
                    </ul>
                    {section.items.length > 5 && (
                      console.log("items",section.items.length ),
              <Button onClick={() => setShowMore(!showMore)}>
                {showMore ? "See More" : "See Less"}
              </Button>
            )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </CustomTabPanel>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FAQS;
