import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Settings And Options",
};

const defaultTheme = createTheme();

const HostSettingsAndOptions = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Viewing Reservations */}
        <section id="ViewingReservations">
          <Box mt={4} mb={2}>
          { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
            <ContentTitleWithLine text="Viewing Reservations" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                For viewing reservations that have been made against your fleet,
                you can navigate to the “Reservations” tab.
              </p>

              <p>
                The reservations have various “Reservation Status” that are
                display the state that the reservation is currently in:-
                <ul type="1" >
                <li>
                  <b>Active -&nbsp;</b>
                  Indicates that the reservation is on going, and the Guest is
                  currently in possession of the vehicle Navigate to the "Price
                  Plans" section within the "Inventory" Tab.
                </li>
                <li>
                  <b>Cancelled -&nbsp;</b>
                  Indicates that the reservation has been cancelled{" "}
                </li>

                <li>
                  <b>Abandoned -&nbsp;</b>
                  Indicates that the reservation drop off time has past and the
                  Guest did not yet collect the vehicle.{" "}
                </li>

                <li>
                  <b>Upcoming -&nbsp;</b>
                  Indicates that the reservation is scheduled for a future time
                  and is not available for the Guest to collect{" "}
                </li>

                <li>
                  <b>Ready to Start -&nbsp;</b>
                  Indicates that the vehicle associated with the reservation is
                  available to the Guest for collection.
                </li>

                <li>
                  <b>Completed -&nbsp;</b>
                  Indicates that the reservation has been ended.
                </li>

                <li>
                  <b>Running Late -&nbsp;</b>
                  Indicates that drop-off time associated with a reservation has
                  past and the vehicle associated with a reservation has not
                  been returned yet.
                </li>
              </ul>
              Reservations for which collection of charges and fees has been
                tried and failed are shown with the option to “Collect” (these
                reservation have the “Payment Status” set to “Collection Due”)
              </p> 
            </Typography>
          </Box>
        </section>

        {/* Editing an existing Price Plan */}
        <section id="PreventingOrDeleting">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Preventing reservation or Deleting vehicle from Inventory" />
            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem" 
              }}
            >
              <ul>
                <li>
                  <p>
                    <b>Prevent Reservation:</b> The "Prevent Reservation" option
                    allows you to temporarily exclude a vehicle from being
                    reserved while keeping it listed in your fleet for future
                    use.</p>
<p>
                    To initiate this action, follow the steps outlined
                    below: 
                    <ol type="a">
                    
                      <li>
                        Navigate to the "Inventory" tab within the application.
                      </li>
                      <li>
                        Select the specific vehicle that you intend to
                        temporarily remove from the system.
                      </li>
                      <li>
                        At the bottom of the vehicle selection screen, you will
                        find the "Prevent Reservation" option.
                      </li>
                      <li>
                        Toggle the button associated with "Prevent Reservation"
                        to activate this feature and prevent the vehicle from
                        being reserved for a limited time.
                      </li>
                    </ol>
                  </p>
                </li>

                <li>
                  <p>
                    <b>Deleting a Vehicle:&nbsp;</b> If required, you have the option
                    to remove a vehicle from your Fleet. 
                    </p>
                    <p>
                    To initiate the
                    deletion process for a vehicle, please follow the steps
                    outlined below
                    <ol type="a">
                      <li>
                        Access the Inventory Tab located within the
                        application's user interface.
                      </li>
                      <li>
                        Identify and select the specific vehicle that you intend
                        to permanently remove from the system.
                      </li>
                      <li>
                        Upon selecting the vehicle, navigate to the lower
                        section of the vehicle selection screen where you will
                        encounter the option labeled “Delete Vehicle”.
                      </li>
                      <li>
                        Proceed by choosing the “Delete Vehicle” option. A
                        subsequent popup screen will appear.
                      </li>
                      <li>
                        Within the popup screen, confirm the deletion by
                        clicking on the “Ok” button.
                      </li>
                    </ol>
                  </p>
                  <p>
                    By completing these steps, the selected vehicle will be
                    successfully removed from your fleet.
                  </p>
                </li>
              </ul>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default HostSettingsAndOptions;
