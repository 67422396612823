import React, { useState } from "react"
import {
  Container,
  Typography,
  useTheme,
  MobileStepper,
  Box,
  Avatar,
  IconButton,
  useMediaQuery,
} from "@mui/material"
import SwipeableViews from "react-swipeable-views"
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew'
import CustomWidgetForContent from "../../../components/WebComponents/CustomTextForContent";
import ContactlessEntryICON from "../../../assets/Icons/home_Icons/contactless_entry.png"
import QuickEasyProcessICON from "../../../assets/Icons/home_Icons/quick_easy_process.png"
import YourRentalRulesICON from "../../../assets/Icons/home_Icons/your_rental_rules.png"
import ZeroMaintenanceStressICON from "../../../assets/Icons/home_Icons/zero_maintenance_stress.png"



const SwipeableViewsComponent = SwipeableViews

const images = [
  {
    label: "Contactless Entry",
    imgPath:`${ContactlessEntryICON}`,
    contentText:"Say goodbye to fumbling with car key & any human intervention for reserving a car. With our fleet of SmartCars, contactless entry is just a reservation away. Simply reserve your vehicle, approach it, and unlock it using our Odpo app. It's that easy, No strings attached."
  },
  {
    label: "Your Rental, Your Rules",
    imgPath:`${YourRentalRulesICON}`,  
    contentText:"Experience the freedom of choosing rental options customized to meet your specific needs. odpo provides a variety of rental solutions designed to match your unique lifestyle and preferences. Whether you're planning a short getaway with loved ones or require a long-term rental for an extended stay, we've got you covered."
    },
  {
    label: "Zero Maintenance, Zero Stress",
    imgPath:`${ZeroMaintenanceStressICON}`,
    contentText:"Experience absolute ease with our services – Zero Maintenance, Zero Stress. Say goodbye to worries about maintenance costs. Our dedicated Hosts guarantee every ride is hassle-free and requires no upkeep from you, ensuring your peace of mind. Embrace the convenience of carefree travel with us.",
  },
  {
    label: "Quick & easy process",
    imgPath:`${QuickEasyProcessICON}`,
    contentText:"Experience a fast and hassle-free car rental process that gets you on the road in no time. We value your time and have optimized every aspect of the car rental journey. Effortlessly explore our extensive range of vehicles, accompanied by comprehensive details and clear pricing information."
     },
]


const CardDataListForWeb = () => {

  const isMobileView = useMediaQuery("(max-width: 767px)");

  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = images.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  const titleText = {
    title: "Benefits of Odpo",
    description:
      "Odpo transforms your travel with advanced technology, ensuring hassle-free experiences. Enjoy contactless entry, flexible rental choices, maintenance-free commuting, a straightforward booking process, and many more features. Discover a seamless journey with us today!",
  }

  return (
    <Container
      maxWidth={false}
      style={{
        background: isMobileView ? null : "#1F282D",
        padding: "0",
        margin: "0",
        paddingBottom: isMobileView ? "0" : "20px"
      }}
    >
      <div style={{
        padding: "2vh",
        justifyContent: "left",
        alignItems: "left",
      }}>
        {
          isMobileView &&
          <Box
            sx={{
              maxWidth: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontSize="1.5rem" >
              {titleText.title}
            </Typography>
            <div style={{ marginLeft: "auto" }} >
              <IconButton onClick={handleBack} disabled={activeStep === 0}>
                <ArrowBackIcon sx={{ color: (activeStep === 0) ? "#BEBABA" : "#12229F" }} />
              </IconButton>
              <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                <ArrowForwardIcon sx={{ color: (activeStep === maxSteps - 1) ? "#BEBABA" : "#12229F" }} />
              </IconButton>
            </div>
          </Box>

        }
        {
          !isMobileView &&
          <CustomWidgetForContent
            title={titleText.title}
            subtitle1={titleText.description}
            textAline1="center"
            textAline2="center"
            titleColor="white"
            subtitleColor="white"
          />
        }

      </div>

      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        {
          !isMobileView &&
          <IconButton sx={{ flex: "initial", marginLeft: "auto", marginRight: "auto" }} onClick={handleBack} disabled={activeStep === 0}>
            <ArrowBackIcon sx={{ color: (activeStep === 0) ? "#BEBABA" : "white" }} />
          </IconButton>
        }

        <Box
          borderRadius={"4px"}
          sx={{
            background: "#F6F6F6",
            padding: "1.5rem",
          }}
        >
          <SwipeableViewsComponent
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            style={{ maxWidth: "800px" }}
          >
            {images.map((step, index) => (
              <div key={step.label} style={{
                width: "100%",
              }} >
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100px",
                      }}
                    >
                      <Avatar
                        sx={{
                          marginBottom: "16px",
                          width: "100%",
                          height: "auto",
                        }}
                        alt={step.label}
                        src={step.imgPath}
                      />
                    </Box>

                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        marginBottom: "8px",
                        color:'#12229F'
                      }}
                      fontWeight={600} 
                    >
                      {step.label}
                    </Typography>
                    <div>
                      <Typography
                        variant={!isMobileView&&"h6"}
                        sx={{ lineHeight: "1.5", color: "#343434" }}
                        
                      >
                        {step.contentText}
                      </Typography>
                    </div>
                  </Box>
                ) : null}
              </div>
            ))}
          </SwipeableViewsComponent>

          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F6F6F6",
              marginTop: "2rem",
              '& .MuiMobileStepper-dotActive': {
                backgroundColor: '#1F282D',
              },
            }}
          />
        </Box>
        {
          !isMobileView &&
          <IconButton sx={{ flex: "initial", marginRight: "auto", marginLeft: "auto" }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            <ArrowForwardIcon sx={{ color: (activeStep === maxSteps - 1) ? "#BEBABA" : "white" }} />
          </IconButton>
        }


      </Box>
    </Container>
  )
}

export default CardDataListForWeb
