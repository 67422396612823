import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Host Partners",
};

const defaultTheme = createTheme();

const HostPatners = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Host Partner*/}
        <section id="HostPartner">
          <CustomWidgetForHeader title={titleText.title} />
          <Box mt={4} mb={2}>
          { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                As a Business, you can add host partners to your account to
                manage your fleet. You can provide three types of access to Host
                partners: Fleet Operations, Customer Support, and
                Administrations.
              </p>
              <p>Follow these steps to add a host partner to your account:</p>

              <ol type="1">
                <li>Navigate to the "People" tab in your account.</li>
                <li>
                  Click on the "+" icon to initiate the process of adding a new
                  partner.
                </li>
                <li>
                  Enter the partner's email address, first name, last name, and
                  select the type of access you want to grant (Fleet Operations,
                  Customer Support, or Administration).
                </li>

                <li>
                  Click on "Send Invite" to send the invitation to the partner.
                </li>

                <li>
                  Once the partner accepts the invitation, they will be listed
                  as an Active Host Partner in the People tab.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/* {Removing Host partners} */}
        <section id="RemovingHostpartners">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Removing Host partners" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                If you need to remove a host partner from your account, you can
                do so by following these steps:
              </p>
              
              <ol type="1" >
                <li>Navigate to the "People" tab in your account.</li>
                <li>
                  Click on the "+" icon to initiate the process of adding a new
                  partner.
                </li>
                <li>
                  Enter the partner's email address, first name, last name, and
                  select the type of access you want to grant (Fleet Operations,
                  Customer Support, or Administration).
                </li>

                <li>
                  Click on "Send Invite" to send the invitation to the partner.
                </li>

                <li>
                  Once the partner accepts the invitation, they will be listed
                  as an Active Host Partner in the People tab.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostPatners;
