import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Getting Started",
};

const defaultTheme = createTheme();

const HostGettingStarted = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/*Adding vehicle to inventory*/}
        <section id="AddingVehicleInventory">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Adding vehicle to inventory" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              {/* Todo implement redirection to ligibility Requirements Page. */}
              <p>
                As a host on Odpo, you have the ability to expand your fleet by
                adding vehicles. Before adding vehicles, it's essential to set
                up price plans and stations for a seamless inventory management
                experience. Additionally, ensure your vehicles meet the
                specified criteria outlined on our Eligibility Requirements
                Page.
              </p>
              <p>Follow below steps to add vehicle to Inventory:</p>

              <ol type="1">
                <li>Once Logged in, Navigate to "Inventory Tab".</li>
                <li>Click on the "+" icon located below the Reports option.</li>
                <li>
                  A popup screen named "Add Inventory screen" will appear with
                  two options:
                  <ol type="a">
                    <li>
                      <b>Manually:&nbsp; </b>
                      Choose this option if you want to manually update all the
                      vehicle details.
                    </li>
                    <li>
                      <b>Integrate via OEM:&nbsp; </b>
                      Select this option to add vehicles through the
                      manufacturer's website. Please note that only basic
                      vehicle details will be fetched from the manufacturer's
                      website, and you'll need to update the remaining details.
                    </li>
                  </ol>
                </li>

                <li>
                  Verify Details:
                  <ol type="a">
                    <li>
                      If you choose "Manually", provide all the necessary
                      details for the vehicle, ensuring accuracy and
                      completeness.{" "}
                    </li>
                    <li>
                      If you opt for "Integrate via OEM", review the fetched
                      basic vehicle details.
                    </li>
                  </ol>
                </li>
                <li>
                  After Double-checking the vehicle details, click on the back
                  button to view the newly added vehicle in your inventory.
                </li>
              </ol>
            </Typography>
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>Note:</p>
              <ul>
                <li>
                  The vehicle will become available for reservation only after
                  it's approved by the organization.
                </li>
                <li>
                  Only vehicles tagged as "Available" will be visible for users
                  to reserve.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>
        {/* Adjusting Vehicle Availability */}
        <section id="AdjustingVehicleAvailability">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Adjusting Vehicle Availability" />
            <Typography
              sx={{fontSize:isMobile?"body2":"1.5rem" }}
            >
              <ol type="1" >
                <li>Navigate to the "Inventory" section of your account.</li>
                <li>
                  Locate the specific vehicle you wish to adjust and click on
                  it.
                </li>
                <li>
                  Look for the "Adjust Availability" option and click on it.
                </li>
                <li>You will be taken to the adjust availability screen.</li>
                <li>
                  Select the type of setting you want to adjust:
                  <ol type="a">
                    <li>
                      To prevent the vehicle from being reserved on specific
                      days, use the top of the screen option displaying
                      weekdays. Select the day(s) you want to prevent
                      reservations.
                    </li>
                    <li>
                      To adjust availability as per date, below the days, click
                      on the "+" option near the "Add Vehicle Unavailability
                      Dates" text. Select the start date and time to the end
                      date and time for which you want to prevent vehicle
                      reservations. Click on the "Select Date" button to save
                      changes.
                    </li>
                  </ol>
                </li>
                <li>
                  To edit any changes made above, click on the edit icon{" "}
                  <ModeEditTwoToneIcon fontSize="large" /> and follow the same
                  process as above.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/*Vehicle Eligibility*/}
        <section id="VehicleEligibility">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Vehicle Eligibility" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              {/* Todo Implement redirection to Eligibility Requirements Page */}
              <p>
                Your vehicle can be listed on Odpo's platform if it aligns with
                the criteria mentioned in our Eligibility Requirements Page.
                This includes aspects like the type of vehicle, its year,
                mileage, value, technology, maintenance, alterations, and more.
                Please ensure your vehicle meets these criteria for a successful
                listing. .
              </p>
            </Typography>
          </Box>
        </section>

        {/* Vehicle Categories */}
        <section id="VehicleCategories">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Vehicle Categories" />
            <Typography
              sx={{fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                Two distinct categories of vehicles can be integrated into the
                Odpo platform, as outlined below:
              </p>
            </Typography>
            {/* Vehicles with Telematic Capabilities */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <b>Vehicles with Telematic Capabilities</b>
              <ul>
                <li>
                  <u>Requirements: &nbsp;</u> Before incorporating Odpo-supported
                  vehicles equipped with telematic features (such as keyless
                  entry and vehicle location) via the original equipment
                  manufacturer (OEM) native applications (e.g., "Remote Connect"
                  for Toyota, "FordPass" for Ford, etc.), it is imperative that
                  these vehicles have an active subscription to the
                  corresponding services within the OEM native apps.
                </li>
                <li>
                  To seamlessly integrate vehicles boasting telematic
                  capabilities into your "Inventory", follow the steps outlined
                  below:
                  <ol>
                    <li>
                      Access the "Inventory" tab and locate the "+" symbol.
                    </li>
                    <li>
                      In next pop-up screen, select "Integrate Via OEM" to
                      initiate the integration process.
                    </li>
                    <li>
                      Follow the instructions to proceed with the integration.
                    </li>
                  </ol>
                </li>
                <li>
                  Additionally, if the added vehicles support Keyless Entry, you
                  will gain the advantage of facilitating vehicle exchanges with
                  Guests sans the necessity of in-person meetings. This
                  streamlined process enhances convenience and efficiency for
                  both hosts and Guests alike.
                </li>
              </ul>
            </Typography>
            {/* Vehicles without Telematic Capabilities */}
            <Typography
              sx={{ fontSize: "1.5rem" }}
            >
              <b>Vehicles without Telematic Capabilities</b>
              <ul>
                <li>
                  If you intend to incorporate vehicles lacking telematic
                  capabilities into the system or if you wish to add Odpo
                  supported vehicles that possess telematic capabilities, but
                  are not currently subscribed to these services, the following
                  steps can be followed:
                  <ol>
                    <li>Access the “Inventory” tab.</li>
                    <li>
                      Click on the "+" symbol to initiate the addition of
                      vehicles.
                    </li>
                    <li>Opt for the "Manual" option.</li>
                    <li>
                      Proceed by adhering to the provided instructions to
                      seamlessly complete the vehicle addition process.
                    </li>
                  </ol>
                </li>
              </ul>
            </Typography>
          </Box>
        </section>

        {/* Station Setup*/}
        
        <section id="StationSetup">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Station Setup" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
              As a host, it is necessary to create stations of your choice.
                  Stations are locations from where guests will pick up and drop
                  off reserved vehicles. You can create multiple stations and
                  allocate various vehicles from the inventory to each station.
              </p>
              <p>
              Follow the steps below to create and manage stations
                  effectively:
                  <ol type="1">
                    <li>
                      Access the "Inventory" tab after logging into your Odpo
                      account.
                    </li>
                    <li>
                      Find and select the "Stations" option within the
                      "Inventory" tab located at top of screen.
                    </li>
                    <li>
                      View existing stations and click on the search box to
                      search and add a new location.
                    </li>
                    <li>
                      Follow the instructions to add a station, providing
                      accurate location details.
                    </li>
                    <li>
                      Optionally, define station boundaries and add helpful
                      notes for guests.
                    </li>
                    <li>
                      Click on “Save” to add the station to stations list.
                    </li>
                  </ol>
              </p>
            
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostGettingStarted;
