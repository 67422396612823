import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Managing Vehicle Damage",
};

const defaultTheme = createTheme();

const HostManagingVehicleDamage = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/*Requesting payment for additional damage repairs*/}
        <section id="RequestingPayment">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Requesting payment for additional damage repairs" />
            {/* Todo click here need to implement */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p />
              If a Vehicle is damaged during a reservation from guest, Host can
              request this additional damage repair cost from Guest.
              <p>
                To recover this expense from the guest, please follow below
                steps:
                <ol type="1">
                  <li>
                    Within the "Reservations" tab, review the list of
                    reservations.
                  </li>
                  <li>
                    Identify and tap on the specific reservation for which you
                    intend to recover toll amount.
                  </li>
                  <li>
                    Within the selected reservation, locate and click on the
                    "Reservation Charges" option.
                  </li>
                  <li>
                    Upon selecting "Reservation Charges", you will be navigated
                    to "Payment Details" screen.
                  </li>
                  <li>
                    At the bottom of the screen, click on "Collect Charges"
                    option.
                  </li>
                  <li>
                    Upon clicking "Collect Charges" option, new popup screen of
                    "Collect Payment" will open.
                  </li>
                  <li>
                    Update amount under "Additional Amount", Select reason as
                    “Others” with a detailed note below the reason and click on
                    "Collect Charges" Option. Successful request will result in
                    a popup screen stating "Payment was successful. Please
                    verify your transactions after 10 minutes”.
                  </li>
                  <li>
                    Now, the charge will reflect under reservation charges as
                    "Charges and Fees" and will be collected at the time guest
                    make final payment.
                  </li>
                </ol>
                {/* Todo implement redirection for Host-Initiated Charges Page */}

                For further information, refer to our Host-Initiated Charges Page.

              </p>
            </Typography>
          </Box>
        </section>

         {/*Replacement vehicles if reserved vehicle is late*/}
         <section id="ReplacementVehicles">
        
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Replacement vehicles if reserved vehicle is late" />
            {/* Todo click here need to implement */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
                <ul>
                    <li>
           If a vehicle is booked immediately one after the other then the second reservation may be impacted if the vehicle is not returned with the drop off time of the first reservation, and the first reservation is treated as “Running Late”.
                    </li>
                    <li>
                    For reservation that are “Running Late” there is an option to “Handle Impacts” , which will enable you to assign different vehicle to the reservations that are impacted because of a previous reservation of the same vehicle “Running Late”.
          
                    </li>
                </ul>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostManagingVehicleDamage;
