import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Safety Protocols",
};

const defaultTheme = createTheme();

const HostSafetyProtocols = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/*Safety recalls*/}
        <section id="SafetyRecalls">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
         
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Safety recalls" />
            {/* Todo click here need to implement */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
              A safety recall is a proactive measure taken by vehicle manufacturers or regulatory authorities to address identified defects or safety-related issues in specific vehicle models, components, or systems. These defects could potentially pose risks to the safety of the driver, passengers, and other road users. To uphold safety standards within our community, vehicle hosts are required to confirm their awareness of any safety recalls associated with their vehicles.
              </p>
              <p>
              Before listing vehicle on our platform, we request all vehicle hosts to ensure they are fully informed about any safety recalls related to their vehicle. If a safety recall has been issued for your vehicle, it is essential that the necessary actions have been taken to resolve the issue completely. This includes having the defect repaired, the faulty component replaced, or any other recommended corrective measures implemented as per the recall instructions.
              </p>
            </Typography>
          </Box>
        </section>

        {/* {Resolving a safety recall} */}
        <section id="ResolvingASafetyRecall">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Resolving a safety recall" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
              As a host, your responsibility includes promptly addressing any safety recalls related to your shared vehicle to ensure the safety and satisfaction of your guests. If you receive a safety recall notification from the manufacturer or regulatory authority, it's important to take prompt action.
              </p>
              <p>
              Below are some important steps to manage the situation effectively:
              <ol type="1" >
                <li>
                    <b>
                    Review the Recall Notification:&nbsp;
                    </b>
                    Thoroughly read and understand the details of the recall provided in the notification. Comprehend the identified defect or safety concern, and grasp the recommended resolution.
                </li>
                <li>
                    <b>
                    Contact the Manufacturer or Authorized Service Center:&nbsp;
                    </b>
                     Reach out to the manufacturer or an authorized service center affiliated with your vehicle's brand. Arrange a recall repair appointment and seek guidance on the necessary actions to resolve the safety recall.
                </li>
                <li>
                    <b>
                    Schedule and Attend the Repair Appointment:&nbsp;
                    </b>
                     Schedule the recall repair appointment promptly and attend it as per the agreed-upon time.
                </li>

                </ol>  
                
                Inform any guests who have upcoming reservations with the affected vehicle. Clearly communicate the situation and offer alternatives, such as rescheduling their reservation or providing an alternative vehicle, if possible.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostSafetyProtocols;
