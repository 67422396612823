// AppBar.js
import {
  useState,
  useRef,
} from 'react'
import {
  AppBar,
  Container,
  Toolbar,
  Menu,
  MenuItem,
  useMediaQuery,
  Typography,
} from '@mui/material'

import {
  useNavigate,
  useLocation,
} from 'react-router-dom'

import OdpoLogoBiggest from '../../assets/CompanyLogo/odpo-logo-biggest'
//import OdpoLogoMobile from '../../assets/CompanyLogo/odpo-logo-mobile'

import Navigation from './navigationBar'

const menuItems = [
  {
    text: "How it works",
    path: "/how-it-works",
  },
  {
    text: "About us",
    path: "/about-us",
  },
  {
    text: "FAQs",
    path: "/faqs",
  },
  {
    text: "Become a host",
    path: "/become-a-host",
  },
]

const MenuBar = () => {

  const location = useLocation()

  const navigate = useNavigate()

  const isMobileView = useMediaQuery("(max-width: 767px)")

  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleMenuClicked = () => {
    setMenuAnchor(menuOpenerRef.current)
  }

  const handleMenuItemClicked = (redirectionUrl) => {
    if (redirectionUrl === location.pathname) {
      return
    }
    navigate(redirectionUrl)
    handleMenuClose()
  }

  const menuOpenerRef = useRef()

  return (
    <AppBar position="fixed" ref={menuOpenerRef} style={{ height: '64px' }} >
      <Toolbar sx={{ display: 'flex', backgroundColor: 'white' }}>
        <Container sx={{ padding: "0.5rem", cursor: "pointer" }} onClick={() => { handleMenuItemClicked("/") }}>
          {
            isMobileView &&
            <OdpoLogoBiggest width={110} />
          }
          {
            !isMobileView &&
            <OdpoLogoBiggest width={130} />
          }
        </Container>
        <Navigation
          handleMenuClicked={handleMenuClicked}
          menuAnchor={menuAnchor}
          menuItems={menuItems}
          handleMenuItemClicked={handleMenuItemClicked}
        />
      </Toolbar>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right', // Set anchor to top right
        }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '100%',
            display: "flex",
            justifyContent: "center"
          },
        }}
      >
        {
          menuItems.map((menuItem) => {
            return (
              <MenuItem onClick={() => { handleMenuItemClicked(menuItem.path) }}>
                <Typography color={location.pathname === menuItem.path ? "#12229F" : "#343434"} >
                  {menuItem.text}
                </Typography>
              </MenuItem>
            )

          })
        }
      </Menu>
    </AppBar>
  )
}

export default MenuBar
