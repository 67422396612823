import React from "react";
import { Typography, ThemeProvider, createTheme } from "@mui/material";

// Define a custom theme
const theme = createTheme();

const CustomWidgetForHeader = ({ title, subtitle1, subtitle2,}) => {

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography style={{color:"black",}} fontSize={'4.25rem'} fontWeight={'600'} mt={4} align={'center'} >
          {title}
        </Typography>
        {subtitle1 && (<Typography style={{color:"black",fontSize:'1.5rem'}} align={'center'} mt={2}  paragraph>
          {subtitle1}
        </Typography>)}
        {subtitle2 && (
          <Typography style={{color:"black",fontSize:'1rem'}} mt={2} paragraph>
            {subtitle2}
          </Typography>
        )}
      </ThemeProvider>
    </div>
  );
};

export default CustomWidgetForHeader;
