import { BrowserRouter } from "react-router-dom"

import MyBrowserRouter from "./MyBrowserRouter"

const Router = () => {
    return (
        <BrowserRouter>
            <MyBrowserRouter />
        </BrowserRouter>
    )
}

export default Router