import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Account Management",
};

const defaultTheme = createTheme();

const HostAccountManagement = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Creating An Account */}
        <section id="CreatingAnAccount">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Creating An Account" />
            <Typography sx={{ fontSize:isMobile?"body2":"1.5rem"}}>
              <p>
              To create a Host account, you will need a valid email address
                  and a valid mobile number, both of which will undergo
                  Verification.
              </p>
              <p>
              Follow below steps to create a Host account:
              <ol type="1">
                    <li>
                      Click on the "Create Account" button. You’ll be navigated
                      to create account screen.
                    </li>
                    <li>
                      On this screen, provide your First Name, Last Name, Email,
                      and a password of your choice according to the specified
                      parameters. Then click on "Create Account" button.
                    </li>
                    <li>
                      Upon clicking, an OTP (One-Time Password) will be sent to
                      your email address for verification.
                    </li>
                    <li>Use OTP to complete the signup process.</li>
                    <li>
                      Post successful Sign-up, you’ll be redirected to Sign-in
                      page.
                    </li>
                    <li>
                      Upon entering the correct credentials on Sign-in page, you
                      will be successfully signed into your account.
                    </li>
                  </ol>
              </p>
            </Typography>
          </Box>
        </section>

        {/*Account Setup */}
        <section id="AccountSetup">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Account Setup" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
              <b>Setting up Odpo Host Account as a Company</b>
              <ul>
                <li>
                  Setting up the Odpo Host Account as a “Company” allows you to
                  add Host Partners with various access levels who can then help
                  manage your manage your fleet of vehicles, as well the manage
                  the reservations associated with your fleet of vehicles.
                </li>
                <li>
                  For setting up the account as “Company” you would be required
                  to provide your Tax ID “EIN” along with other details of
                  Company such as Company Name and Address.
                </li>
                <li>
                  The “Company Phone” should be a mobile number so that it can
                  be used by Guests to reach out to your company if they are
                  having issues with their reservation.
                </li>
                {/* Todo Insurance Page link with related page */}
                <li>
                  Also setting up the account as a “Company” allows you to
                  provide your own Commercial Auto Rental Liability insurance
                  for the vehicles listed on the Odpo platform, refer to
                  Insurance Page for details
                </li>
              </ul>
              </p>
            </Typography>
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
             <p>
             <b>Setting up Odpo Host Account as an Individual</b>
              <ul>
                <li>
                  For setting up an account as an “Individual” you would need to
                  provide your name and address along with the “Phone” number
                  which is the support mobile number that needs to be used by
                  Guests to reach out to you if they are having issues with
                  their reservation.
                </li>
              </ul>
             </p>
            </Typography>
          </Box>
        </section>

        {/* Code For Verification*/}
        <section id="CodeForVerification">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Code For Verification" />
            <Typography
              sx={{fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  To verify your email address, a verification code will be sent
                  to the email used to register your account. Additionally, upon
                  updating your mobile number (which is mandatory), you'll
                  receive a one-time password (OTP) on your mobile number.
                </li>
                <li>
                  Please note that the email ID used to create your account
                  cannot be changed afterwards. It's important to keep your
                  account details safe and handy.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>
        {/*Change your password*/}
        <section id="ChangeYourPassword">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Change Your Password" />
            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem"
              }}
            >
              <p>
              To change password, follow below given steps:
              <ol type="1">
                <li>After logging in, navigate to the "Profile" Tab.</li>
                <li>Go to "Account Details" & Click on "Password Change".</li>
                <li>
                  In Next pop-up screen, Enter your current password & new
                  password.
                </li>
                <li>Click on “Update password” to update your password.</li>
              </ol>
              </p>
            </Typography>
          </Box>
        </section>
        {/* Changing Name or Phone number*/}
        <section id="ChangingNameOrNumber">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Changing Name or Phone number" />
            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem"
              }}
            >
             <p>
             Follow these steps to change your Name or Phone number:
              <ol type="1">
                <li>Once logged in, navigate to the "Profile" Tab.</li>
                <li>
                  Within the "Account Settings" section, locate and select the
                  "Your Information" option.
                </li>
                <li>
                  In next screen, click on Name or Phone Number you want to
                  change.
                </li>
                <li>
                  You’ll be prompted with pop-up screen to change upon selecting
                  any individual options.
                </li>
                <li>
                  Upon updating, select “Update Name“ to save name & “Update
                  number“ to save new number.
                </li>
              </ol>
             </p>
            </Typography>
            <Typography
              sx={{fontSize:isMobile?"0.875":"1.2rem"}}
            >
              <p>
                Note: The mobile number would need to be provided in the
                +(Country Code) - Mobile Number format, e.g. +1 770 777 7777
              </p>
            </Typography>
          </Box>
        </section>

        {/*Password reset*/}
        <section id="PasswordReset">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Password Reset" />

            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem"
              }}
            >
              <p>
              If you"ve forgotten your password and are unable to sign in, you
              can reset it by following below given steps:
              <ol type="1">
                <li>Go to the Sign-In page.</li>
                <li>Click on "Did you forget it?" below the sign-in option.</li>
                <li>
                  Enter the email ID linked to your account & Click "Submit".
                </li>
                <li>
                  Check your email for instructions on how to reset your
                  password.
                </li>
              </ol>
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostAccountManagement;
