// Navigation.js
import {
  Container,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CancelIcon from '@mui/icons-material/Cancel';

import {
  useLocation,
} from 'react-router-dom'

const Navigation = ({
  handleMenuClicked,
  menuAnchor,
  menuItems,
  handleMenuItemClicked,
}) => {

  const isMobileView = useMediaQuery("(max-width: 767px)")

  const location = useLocation()

  return (
    <Container sx={{ display: 'flex', justifyContent: 'flex-end' }} >
      {
        isMobileView &&
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClicked}
        >
          {
            !Boolean(menuAnchor) &&
            <MenuIcon
              sx={{
                color: '#514F4F', // Change the color of the MenuIcon
              }}
            />
          }
          {
            Boolean(menuAnchor) &&
            <CancelIcon
              sx={{
                color: '#12229F', // Change the color of the MenuIcon
              }}
            />
          }

        </IconButton>
      }
      {
        !isMobileView &&
        <List sx={{ display: 'flex', alignItems: 'center' }}>
          {
            menuItems.map((menuItem) => {
              return (
                <ListItem button onClick={() => { handleMenuItemClicked(menuItem.path) }}>
                  <ListItemText sx={{ whiteSpace: "nowrap", color: location.pathname === menuItem.path ? "#12229F" : "#343434" }} primary={menuItem.text} />
                </ListItem>
              )
            })
          }
          {/* Add more navigation items here */}
        </List>
      }


    </Container>

  );
};

export default Navigation;
