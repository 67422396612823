import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Modifying or Canceling Reservations",
};

const defaultTheme = createTheme();

const HostModifyingOrCancelingTrips = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/*Trips canceled by guests */}
        <section id="UpgradingReservation">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Upgrading Reservation" />
            <Typography sx={{fontSize:isMobile?"body2":"1.5rem"}}>
              <p>
              In situations where a guest creates a reservation but the host is unable to provide the reserved vehicle, the host can upgrade to an available vehicle at the current station. To change the vehicle for an upcoming reservation, the host can follow the steps below.
                <ol type="1">
                <li>
                Navigate to the reservations tab.
                  </li>
                <li>
                Select the affected reservation and click on the "Assign New Vehicle" option in the next screen.
                </li>
                <li>
                You'll be redirected to the “Assign vehicle“ screen, where the available vehicle list from the same station as the existing reservation will be visible.
                </li>
                <li>
                After selecting the vehicle and clicking on the proceed button, you'll be redirected to a confirmation screen displaying both the vehicles and reservation details.
                </li>
                <li>
                Once you click on confirm, a popup will confirm the status as a new vehicle is assigned.
                </li>
             </ol>
              </p> 
            </Typography>
            <Typography sx={{fontSize:isMobile?"0.875rem":"1.2rem"}}>
              <p>(Note: Vehicle assigning option is only available for Upcoming Vehicles)</p>
            </Typography>
          </Box>
        </section>

                {/*Trips canceled by guests */}
                <section id="TripsCanceledByGuests">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Trips canceled by guests" />
            <Typography sx={{fontSize:isMobile?"body2":"1.5rem"}}>
              <p>
              Depending on the timing of the cancellation, there are two different scenarios and corresponding outcomes:
             <ul>
                <li>
                    <b>
                    Cancellation Policy (More than 24 Hours Before Reservation Start Time):&nbsp;
                    </b>If a reserved vehicle is cancelled more than 24 hours before the reservation start time, no charges will be deducted from the guest. We understand that plans can change, and we strive to provide our users with flexibility and convenience when it comes to their reservations.
                </li>
                <li>
                    <b>
                    Cancellation Policy (Within 24 Hours Before Reservation Start Time):&nbsp;
                    </b>If a reservation is cancelled within 24 hours before the reservation start time, a certain percentage of the reservation amount will be deducted. The deducted percentage is calculated based on our cancellation policy, ensuring a fair approach to cancellations. Please note that this percentage varies based on the specific reservation and is designed to cover operational costs and is provided to the Host to maintain the integrity of our reservation system.
                </li>
             </ul>
              </p> 
            </Typography>
          </Box>
        </section>

          {/*Guest no-shows*/}
          <section id="GuestAbandoneTrip">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Guest Abandone Trip" />
            <Typography sx={{fontSize:isMobile?"body2":"1.5rem"}}>
                <ul>
                  <li>
                In the scenario where a guest reserves a vehicle but doesn't arrive at the designated location to collect the vehicle, it is marked as a Abandoned. The application promptly triggers notifications to both the guest and the respective host involved in the reservation. These notifications serve as a reminder to the guest about their reservation commitment and the need to collect the vehicle as planned.
                </li>
                <li>
                Upon receiving this notification, the Accountability of collecting the reserved vehicle lies with the guest. Guest must ensure they collect the vehicle within the allocated time frame. However, if the guest fails to collect the vehicle within the specified time despite the notification, a deduction is made from the guest's account.
                </li>
                </ul>
            </Typography>
          </Box>
        </section>

          {/*Host cancelling A reservation*/}
          <section id="HostCancellingAReservation">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Host cancelling a reservation" />
            <Typography sx={{fontSize:isMobile?"0.875rem":"1.2rem"}}>
                <p>
                (Note: Before proceeding with the cancellation, please ensure that the reservation you intend to cancel is in "Upcoming" status. Cancelling a reservation may result in a refund to the customer based on your cancellation policy.)
                </p>
             </Typography>
            <Typography sx={{fontSize:isMobile?"body2":"1.5rem"}}>
              <ol type="1">
                <li>
                Navigate to the "Reservations" tab in your account.
                </li>
                <li>
                Select the reservation you want to cancel.
                </li>
                <li>
                Scroll to the bottom of the reservation details page and locate the "Cancel Reservation" button. Click on it.
                </li>
                <li>
                In the next screen, select the reason for cancellation and proceed.
                </li>
                <li>
                The reservation will be canceled, and the amount will be refunded to the customer.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostModifyingOrCancelingTrips;
