import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Pricing Your vehicle",
};

const defaultTheme = createTheme();

const HostPricingYourVehicle = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Account Registration */}
        <section id="CreatingPricePlans"></section>
        <Box mt={4} mb={2}>
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <ContentTitleWithLine text="Creating Price Plans" />
          <Typography
            sx={{ fontSize:isMobile?"body2":"1.5rem" }}
          >
            <p>
            As a host, you have the ability to create multiple pricing plans to curate distinct categories of vehicles and establish specific criteria for drivers.
            </p>
           
            <p>To initiate the creation of price plans, access the "Inventory" tab and locate the corresponding option situated at the upper-right corner. To both review the current selection of price plans and introduce new ones, kindly adhere to the ensuing guidelines:</p>
            <ol type="1">
              <li>
              Navigate to the "Price Plans" section within the "Inventory" Tab.
              </li>
              <li>
              In this section, extant pricing plans are visible. Should you wish to incorporate new plans, kindly select the "+" icon.
              </li>
              <li>Upon completing the aforementioned steps, the subsequent interface will provide the option to integrate the newly crafted price plan into existing vehicles lacking an assigned plan within the inventory. To enact this, simply select "Save."</li>
              </ol>
              <p>
              By adhering to the above-mentioned procedure, you can seamlessly generate and manage distinct pricing plans tailored to your fleet's diverse vehicle offerings and driver requisites.
              </p>
              <p>
              Example: If a reservation spans a duration of 25 hours, the pricing structure will be computed as the sum of the 1-day pricing and the 1-hour pricing, specifically in the context of time-based pricing. Additionally, the distance allocation for the reservation will adhere to a similar principle, wherein it combines 1-day Distance Included with 1-hour Distance Included.
              </p>

          </Typography>
        </Box>

        {/* Editing an existing Price Plan */}
        <section id="EditingPricePlan">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Editing an existing Price Plan" />
            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem" 
              }}
            >
              <p>
              To modify an existing price plan, kindly proceed with the following instructions:
              </p>
              <ol type="1" >
                <li>Access the "Price Plans" segment located within the "Inventory" Tab.</li>
                <li>
                Within this designated area, you will find a list of current pricing plans. Identify and select the specific plan that requires editing.
                </li>
                <li>
                After selecting the desired plan, you will be presented with the option to revise various parameters according to your requirements. Once the necessary adjustments have been made, kindly ensure to save the updated information.
                </li>
               
              </ol>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default HostPricingYourVehicle;
