import { Typography } from "@mui/material";

const ContentTitleWithLine = ({ text }) => (

  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
    <Typography
      sx={{
        fontSize: "1.25rem",
                "@media (min-width: 600px) and (max-width: 959px)": {
                  fontSize: "1.5rem",
                },
                "@media (min-width: 960px)": {
                  fontSize: "1.8rem",
                },
        color: "#fff",
        backgroundColor: "black",
        padding: "2px 30px",
        paddingRight: "calc(20px + 10%)",
        fontWeight: "bold"
      }}
    >
      {text}
    </Typography>
    <div style={{ backgroundColor: "black", height: "2px", width: "100%" }}></div>
  </div>
);

export default ContentTitleWithLine;