import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Payments / Payouts",
};

const defaultTheme = createTheme();

const HostPaymentsAndPayouts = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* What Is A Payout */}
        <section id="WhatIsAPayout">
          <Box mt={4} mb={2}>
          { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
            <ContentTitleWithLine text="What is a payout" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                Payout is the process through which hosts who have hosted
                vehicles in the Odpo platform gets paid for the reservations
                that have been made in Odpo.
              </p>
            </Typography>
          </Box>
        </section>

        {/* Setting up an account to get paid*/}
        <section id="SettingUpAnAccount">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Setting up an account to get paid" />
            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem" 
              }}
            >
              <p>
                Payments made by guests can be received into your Stripe account
                and subsequently transferred to your personal accounts.
              </p>
              <p>
                To set up an account for receiving payments, please follow the
                steps below:
                <ol type="1">
                  {/* Todo implement click here */}
                  <li>
                    Click on the Profile Tab and select “Payout Details.” You
                    will be redirected to your Stripe account page or click
                    here.
                  </li>
                  <li>
                    Your email ID is already updated in the account details.
                    Create a strong password.
                  </li>
                  <li>
                    A mobile number is required for sign-up and signing in to
                    the application each time to receive OTP (One-Time
                    Password). Use an active mobile number that you have access
                    to.
                  </li>
                  <li>
                    Use the OTP received on your mobile number to access your
                    Stripe account.
                  </li>
                  <li>
                    Before creating a Stripe account, make sure to have the
                    following details ready:
                    <ul>
                      <li>Business Location</li>
                      <li>Business Type (Individual / Company)</li>
                      <li>Industry</li>
                      <li>Website</li>
                      <li>Routing Number</li>
                      <li>Bank Account Number</li>
                    </ul>
                  </li>
                  <li>
                    Update the aforementioned details accurately and confirm
                    them on the next screen.
                  </li>
                  <li>
                    After successfully creating your account, you will be
                    directed to the Stripe Dashboard.
                  </li>
                </ol>
              </p>
              <p>
                Setting up a Stripe account to receive payments involves a
                series of steps to ensure a smooth process. By following the
                provided instructions, you can establish a connection between
                guest payments and your personal accounts. Remember to carefully
                input your business information, choose the correct business
                type, and provide accurate banking details for seamless
                transactions. Your Stripe Dashboard will serve as the hub for
                managing your payments and transactions effectively. Should you
                encounter any issues or need assistance, Stripe's support
                resources are available to help you navigate the process.
              </p>
            </Typography>
          </Box>
        </section>

        {/* Can I update my payout account*/}
        <section id="UpdatePayout">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Can I update my payout account" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                Following the completion of the "Payout Setup" process, you
                retain the authorization to modify the designated payout account
                at your discretion.
              </p>
              <ol type="1">
                <li>Once logged in, Navigate to "Profile" section.</li>
                {/*Todo implement Click here */}
                <li>
                  Select "Payout Details." You’ll be navigated to stripe website
                  or click here and login.
                </li>
                <li>
                  Follow the provided instructions in the "Payout Details"
                  section.
                </li>
                <li>Locate and click on the designated Account icon.</li>
                <li>
                  Utilize the "Payout details" section to make necessary
                  adjustments to the account information.
                </li>
                <li>
                  Ensure the accuracy of the account information for seamless
                  receipt of payouts.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/*Viewing your earnings */}
        <section id="ViewingYourEarnings">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Viewing Your Earnings" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                As a Host, you can track your total earnings received through
                the application using your Stripe account. To view your
                earnings, follow these steps:
              </p>
              <ol type="1">
                <li>Go to the Profile Tab and select “Payout Details”</li>

                <li>
                  You will be directed to your Stripe account. Sign in using
                  your Stripe account credentials.
                </li>
                <li>Click on “Balances”.</li>
                <li>
                  Under the "Balances" tab, you'll find the total amount, which
                  includes both current and upcoming payouts.
                </li>
              </ol>
            </Typography>
            {/* Todo Implement Click Here */}
            <Typography
              sx={{
                fontSize: "1.2rem",
                p: "8px",
              }}
            >
              (Note: If you encounter issues locating or opening “Payout
              Details”, Click Here)
            </Typography>
          </Box>
        </section>

         {/*Handling refund request*/}

        <section id="HandlingRefundRequest">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Handling refund request" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"  }}
            >
              <p>
                As a Host, you have the option to issue refunds to customers
                either through the mobile application or the Stripe website.
                These instructions will guide you through the process of
                handling refund requests effectively using both methods.
              </p>
            </Typography>
            {/* Refunding via Mobile Application: */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"  }}
            >
              <p>
                <b>Refunding via Mobile Application:</b>
              </p>
              <p>
                To handle refund from mobile application, follow below steps:
                <ol type="1" >
                  <li>
                    Within the "Reservations" tab, review the list of
                    reservations.
                  </li>
                  <li>
                    dentify and tap on the specific reservation for which you
                    intend to initiate a refund.
                  </li>
                  <li>
                    Within the selected reservation, locate and click on the
                    "Reservation Charges" option.
                  </li>
                  <li>
                    Upon selecting "Reservation Charges", you will be presented
                    with a new screen. On this screen, you will find a list of
                    recent transactions linked to the reservation.
                  </li>
                  <li>
                    Scan through the list of transactions and identify the
                    particular one that necessitates a refund. Tap on the
                    identified transaction to select it.
                  </li>
                  <li>
                    After selecting the transaction, access the dropdown menu
                    provided on the interface. From the dropdown options, choose
                    the "Refund" option.
                  </li>
                  <li>
                    You will be automatically directed to the "Refund Payment"
                    screen.
                  </li>
                  <li>
                    On the "Refund Payment" screen, begin by entering refund
                    amount, then the reason for the refund from the available
                    options, such as "Duplicate", "Fraudulent", "Requested by
                    Customer", or "Other". If applicable, input additional text
                    by composing a note. Once all information is entered,
                    finalize the process by tapping the "Refund Payment" button.
                  </li>
                </ol>
              </p>
            </Typography>
            {/* Refunding via Stripe Website */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                <b>Refunding via Stripe Website:</b>
              </p>

              <ol type="1" >
                <li>
                  Within the Profile tab, locate and select the "Payout Details"
                  option.
                </li>
                <li>
                  You will be redirected to your Stripe account page. Sign in
                  using your Stripe account credentials.
                </li>
                <li>
                  From your Stripe account dashboard, identify and select the
                  specific Payment Intent for which you want to process a
                  refund.
                </li>
                <li>
                  In the following screen, you will find the "Refund" option
                  positioned at the top-left corner of the screen. Click on it.
                </li>
                <li>
                  In the refund interface, input the amount of the refund you
                  intend to issue.
                </li>
                <li>
                  Select the reason for the refund from the provided options,
                  including Duplicate, Fraudulent, Requested by Customer or
                  Other & if required, enter a note to provide additional
                  information.
                </li>
                <li>
                  Once all details are entered, click the "Refund Payment"
                  button to confirm and initiate the refund.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

         {/*Charges for Tolls, Tickets, Refueling, Cleaning, for smoking in vehicle*/}
         <section id="ExtraCharges">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Charges for Tolls, Tickets, Refueling, Cleaning, for smoking in vehicle" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"  }}
            >
              <p>
              When a vehicle is reserved by guest and encounters a toll road during their journey, it becomes the responsibility of the guest to cover the toll charges. However due to Electronic Toll Collection (ETC), amount is deducted from the host's account. Host can recover this charge from guest. Also if there are any other additional expense from the guest, Host can recover them as well by following below steps:
             
              <ol type="1">
                <li>Within the "Reservations" tab, review the list of reservations.</li>

                <li>
                Identify and tap on the specific reservation for which you intend to recover toll amount.
                </li>
                <li>Within the selected reservation, locate and click on the "Reservation Charges" option.</li>
                <li>
                Upon selecting "Reservation Charges", you will be navigated to "Payment Details" screen.
                </li>
                <li>
                At the bottom of the screen, click on "Collect Charges" option.
                </li>
                <li>
                Upon clicking "Collect Charges" option, new popup screen of "Collect Payment" will open.
                </li>
                <li>Update amount under "Additional Amount", Reason as "Toll" if recovering for Toll/ “Fuel” if related with fuel/ “Cleaning” if vehicle is left unclean & Late return, vehicle relocation or “Others” Reason for any other charges occurred during usage of vehicle and note if you want any and click on "Collect Charges" Option. Successful request will result in a popup screen stating "Payment was successful. Please verify your transactions after 10 minutes”.
                </li>
                <li>
                Now, the charge will reflect under reservation charges as "Charges and Fees" and will be collected at the time guest make final payment.
                </li>
              </ol>
              {/* click implement Host-Initiated Charges Page. */}
              For further information, refer to our Host-Initiated Charges Page.
              </p>
            </Typography>
          </Box>
        </section>

          {/*Damage report policy*/}
          <section id="DamageReportPolicy">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Damage report policy" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"  }}
            >
                {/* Todo click implement Damage Report Policy  */}
              <p>
              Ensuring transparency and accountability, our damage report policy mandates that if you suspect damage caused by a guest to your vehicle and have not declined the protection plan, promptly report it within 24 hours of the reservation's end via the 'File a Claim' option in reservation details. Cooperation during the investigation is essential for coverage eligibility. For further details, refer to our Damage Report Policy page.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default HostPaymentsAndPayouts;
