import React from "react";
import { Typography, ThemeProvider, createTheme } from "@mui/material";

// Define a custom theme
const theme = createTheme();

const CustomWidgetForMob = ({ title, subtitle1, subtitle2,customAlignText,paragraphAline, }) => {

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Typography fontSize={'1.5rem' } fontWeight={"600"} mt={4} align={customAlignText}  >
          {title}
        </Typography>
        {subtitle1 && (
          <Typography fontSize={'1rem'} mt={2} align={customAlignText} paragraph={false||paragraphAline} >
            {subtitle1}
          </Typography>
        )}
        {subtitle2 && (
          <Typography fontSize={".875rem"} mt={2} align={customAlignText} paragraph={false||paragraphAline}>
            {subtitle2}
          </Typography>
        )}
      </ThemeProvider>
    </div>
  );
};

export default CustomWidgetForMob;
