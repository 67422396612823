import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Driver Responsibilities",
};

const defaultTheme = createTheme();

const DriverResponsibilities = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Quick Start Guide */}
        <section id="ProhibitedUse">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}

          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Prohibited use" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                Prohibited activities on Odpo's Services include gathering or
                using personal information, impersonation, fraud, disrupting
                server transmissions, and unauthorized access or circumvention
                of restrictions. This ensures a secure and respectful
                environment for all users. For detailed guidelines, refer to our
                Odpo Service Rules page.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default DriverResponsibilities;
