import * as React from 'react';
import {useMediaQuery,Paper,Typography} from '@mui/material';

const mobStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height:"350px"
  },
  image: {
    width: '100%',    
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',
  },
};
const webStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  image: {
    width: '45%', 
    '@media (max-width: 300px)': {
      width: '100%',
      paddingBottom: '150%', 
    },
  },
  content: {
    width: '55%', 
    padding: '1rem',
    '@media (max-width: 600px)': {
      width: '100%', 
    },
  },
};

export default function ComplexGrid({images, title,subtitle}) {
  const isMobileView = useMediaQuery("(max-width: 767px)");
  return (
    <Paper style={isMobileView ? mobStyles.container : webStyles.container}>
    <img src={images} alt={title} style={isMobileView ? mobStyles.image : webStyles.image} />
    <div style={isMobileView ? mobStyles.content : webStyles.content}>
      <Typography variant={isMobileView?"1rem":"h6"} fontWeight={"600"} sx={{p:"auto"}} gutterBottom>
        {title}
      </Typography>
      <Typography variant={isMobileView?"0.835rem":"h6"} paragraph>
        {subtitle}
      </Typography>
    </div>
  </Paper>

  );
}
