import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Managing Reservations",
  
};

const defaultTheme = createTheme();

const HostManagingReservations = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Verifying a Guest license*/}
        <section id="VerifyingAGuestLicense">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Verifying a Guest license" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                A Guest using Odpo app to reserve a vehicle is verified using
                Driving License details. In order to access and verify the guest
                details, who will be using the Odpo app to reserve a vehicle,
                follow below steps:
                <ol type="1" >
                  <li>
                    Open Reservations and identify the reservation for which you
                    need to verify customer details.
                  </li>
                  <li>
                    Click on the Guest Name located above the reservation ID.
                  </li>

                  <li>
                    You will be directed to the "Customer Details" screen, where
                    all guest details can be thoroughly reviewed and verified.
                  </li>
                </ol>
              </p>
            </Typography>
          </Box>
        </section>

        {/*Requests to extend trip*/}
        <section id="RequestsToExtendTrip">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Requests To Extend Trip" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                If a guest wishes to extend their existing reservation, they can
                effortlessly do so through the "Extend Trip" option within the
                application. However, there are two different scenarios:
                <ol type="1">
                  <li>
                    <b>Availability for Extension - Auto Approval:&nbsp;</b>If the vehicle that a guest wishes to extend the reservation
                    for is not reserved by another guest for the requested date
                    and time, the system will automatically approve the
                    extension.
                  </li>
                  <li>
                    <b>
                      Extension Availability Based on Subsequent Reservation:&nbsp;
                    </b>If the vehicle has already been reserved by another guest
                    for the date and time the extension is requested, the guest
                    seeking the extension will receive a pop-up notification.
                    This notification will inform them that the extension is
                    available only until the DD-MM date and TT time. The time
                    mentioned will be set at 30 minutes before the start of the
                    subsequent guest's reservation.
                  </li>
                </ol>
                Approval process for vehicle extension requests is fully
                automated and does not involve any manual intervention. When a
                guest requests an extension for a reservation, the system
                automatically processes the request based on availability and
                predefined rules. If the vehicle is available for the requested
                period, the extension is approved seamlessly, ensuring a
                convenient experience for our users.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostManagingReservations;
