import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Getting Started",
};

const defaultTheme = createTheme();

const GettingStarted = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Account Registration */}
        <section id="AccountRegistration">
          <Box mt={4} mb={2}>

          { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}

            <ContentTitleWithLine text="Account Registration" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                To create a driver account, you will need a valid email address
                and a valid mobile number, both of which will undergo
                validation.
              </p>
              <p>There are two methods for creating a driver account:</p>
              <p>Method One:</p>
              <ol type="1" style={{ lineHeight: 2 }}>
                <li>
                  Provide your First Name, Last Name, Email, and a password of
                  your choice according to the specified parameters.
                </li>
                <li>
                  Once you have provided all the required details, an OTP
                  (One-Time Password) will be sent to your email address for
                  verification.
                </li>
                <li>Use the OTP to complete the signup process.</li>
                <li>
                  Sign in using your verified Email address and Password. Upon
                  entering the correct credentials, you will be successfully
                  signed into your account.
                </li>
              </ol>
              <p> Method Two:</p>
              <ol>
                <p>
                  Alternatively, you can choose to sign up using an existing
                  social media account on your device. You can use accounts from
                  Facebook, Google, or Apple to log in to the application.
                </p>
              </ol>
            </Typography>
          </Box>
        </section>

        {/* Code for Verification */}
        <section id="EditingnPricePlan">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Editing an existing Price Plan" />

            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                To verify your email address, a verification code will be sent
                to the email used to register your account. Additionally, upon
                updating your mobile number (which is mandatory), you'll receive
                a one-time password (OTP) on your mobile number.
              </p>
            </Typography>
            <Typography
              sx={{ fontSize: isMobile?"0.875rem":"1.2rem"}}
            >
              Please note that the email ID used to create your account cannot
              be changed afterwards. It's important to keep your account details
              safe and handy.
            </Typography>
          </Box>
        </section>
        {/* Change your password */}
        <section id="ChangePassword">
          <Box>
            <ContentTitleWithLine text=" Change Your Password" />
            <Typography
              sx={{
                fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                Follow these steps to change your password in just a few clicks:
              </p>
              <ol>
                <li>Once logged in, navigate to the "Profile" Tab.</li>
                <li>
                  Within the "Account Settings" section, locate and select the
                  "Change Password" option.
                </li>
                <li>
                  In the next pop-up screen, you will be prompted to input your
                  current password and your desired new password. Ensure your
                  new password meets the specified criteria.
                </li>
                <li>
                  After providing the required passwords, click on an option
                  "Update Password" to complete the process.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/* Changing Name or Phone number */}
        <section id="ChangeNamePhone">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Changing Name or Phone number" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>Follow these steps to change your Name or Phone number:</p>

              <ol>
                <li>Once logged in, navigate to the "Profile" Tab.</li>
                <li>
                  Within the "Account Settings" section, locate and select the
                  "Your Information" option.
                </li>
                <li>
                  In next screen, click on Name or Phone Number to change.
                </li>
                <li>
                  You’ll be prompted with pop-up screen to change upon selecting
                  individual options.
                </li>
                <li>
                  Upon updating, select “Update Name“ to save name & “Update
                  number“ to save new number.
                </li>
              </ol>
            </Typography>
            <Typography
              sx={{
                fontSize:isMobile?"0.875rem":"1.2rem",
                p: "8px",
              }}
             
            >
              (Note: The mobile number would need to be provided in the
              +(Country Code) - Mobile Number format, e.g. +1 770 777 7777)
            </Typography>
          </Box>
        </section>

        {/* Password Reset */}
        <section id="PasswordReset">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Password Reset" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                If you've forgotten your password and can't log in, follow below
                steps to reset it and access your account:
              </p>
              <ol type="1">
                <li>
                  Go to the sign-in page and locate the option that says "Did
                  you forget it?" and select.
                </li>
                <li>Provide the email address associated with your account.</li>
                <li>
                  Submit your email ID, and you will receive further
                  instructions via email on how to reset your password.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default GettingStarted;
