import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Maintaining Your Vehicle",
};

const defaultTheme = createTheme();

const HostMaintainingYourVehicle = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/*Maintenance requirements*/}
        <section id="MaintenanceRequirements">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Maintenance requirements" />
            {/* Todo click here need to implement */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
              Ensuring your vehicle's safety and compliance is vital. Regular checks and maintenance are required to keep it roadworthy. Click here to read our guidelines for Maintenance.
              </p>
            </Typography>
          </Box>
        </section>

        {/* {Flat tire damage} */}
        <section id="FlatTireDamage">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Flat tire damage" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
              In the unfortunate event of a flat tire, kindly note that the responsibility for repair lies with the guest. While we understand the unexpected nature of such incidents, guests are required to arrange and cover the costs of tire repair or replacement. We recommend promptly contacting a local tire service provider or certified mechanic to assess the damage and determine the necessary action.
              </p>
              <p>
              Preventing flat tires is important for a smooth experience. Regular tire maintenance, including proper inflation and checks for wear and tear, can significantly minimize the risk. We appreciate your understanding and cooperation in maintaining the quality of our services. Should you require assistance or further information on handling flat tire situations, our guest services are available to help. Your safety and comfort are our priorities, and together, we can ensure a pleasant stay.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostMaintainingYourVehicle;
