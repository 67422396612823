import {
    useState,
    useEffect,
} from 'react'

import {
    useLocation,
} from 'react-router-dom'

import {
    request,
    gql,
} from 'graphql-request'

const getStatusQuery = gql`
    query StripeAccountStatus($token: String!) {
        getStripePayoutAccountStatus(token: $token)
    }
`

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

const PayoutSetupComplete = () => {

    const query = useQuery()

    const token = query.get("token")

    useEffect(() => {
        if(token) {
            checkStatusRecursion(token, setError)
        }
    }, [])

    const [error, setError] = useState()

    if(!token) {
        setTimeout(() => {
            window.location.href = "https://dashboard.stripe.com/account/status"
        }, 3000)
        return(
            <div>
                Invalid request, you are redirecting to Stripe dashboard
            </div>
        )
    }
    

    if(error) {

        setTimeout(() => {
            window.location.href = "https://dashboard.stripe.com/account/status"
        }, 3000)

        return (
            <div>
                We faced an error. You will redirecting to Stripe Dashboard
            </div>
        )
    }

    return (
        <div>
            <div>
                Your payout setup is almost complete. You have few more steps. You will be redirected shortly. Please wait.
            </div>
        </div>
    )

}

const checkStatusRecursion = async (token, setError) => {

    const result = await checkStatusQuery(token)

    if(result === "READY_TO_REDIRECT") {
        window.location.href = "https://dashboard.stripe.com/account/status"
        return
    }

    if(result === "ERROR") {
        setError("result")
        return
    }

    setTimeout(() => {
        checkStatusRecursion(token)
    }, 3000)

}

const checkStatusQuery = async (token) => {
    try{
        const response = await request(
            'v2/gateway',
            getStatusQuery,
            {
                token,
            },
        )
        return response.getStripePayoutAccountStatus
    }
    catch {
        return "ERROR"
    }
    
}

export default PayoutSetupComplete