import React, {useEffect} from "react";
import MainFeaturedPost from "./components/MainFeaturedPost";
import {
  Typography,
  useMediaQuery,
  Grid,
  styled,
  Paper,
  Button,
  ThemeProvider,
  createTheme,
  Container,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import CustomWidgetForHeader from "../../components/WebComponents/CustomTextForHeader";
import CustomWidgetForContent from "../../components/WebComponents/CustomTextForContent";
import createAccount from "../../assets/Icons/create_account_icon.png"
import download from "../../assets/Icons/download_icon.png"
import drive from "../../assets/Icons/drive_icon.png"
import explore from "../../assets/Icons/explore_icon.png"
import becameahost from "../../assets/Background/homescreen/become_a_host.png"
import CustomWidgetForMobile from "../../components/MobComponents/CustomTextForMob"
import CardDataListForWeb from "./components/CardDataListForWeb"

const mainFeaturedPost = {
  title: "Hit the road",
  subtitle: "stress free",
  description:
    "Odpo strives to offer a convenient, flexible, and affordable overall experience for your dreamy road trip adventures. Odpo will take care of you throughout your journey.",
  imageText: "main image description",
  linkText: "Continue reading…",
};

const titleText = {
  title: "Drive without worries",
  description:
    "At Odpo, we prioritize your peace of mind when you hit the open road. Our fleet includes vehicles equipped with the latest technology, some featuring lane departure warnings, adaptive cruise control, and many more advanced features. Moreover, our dedicated Hosts are always ready to support you in case of any concerns or emergencies. Rediscover the sheer joy of driving without a care in the world and embark on your journey with unwavering confidence in our dependable and secure vehicles. Your safety and satisfaction are our utmost priorities.",
};

const infoText = {
  title: "Odpo in 4 easy steps",
  description:
    "Unlock the world of Odpo in just four simple steps. Thanks to Odpo's streamlined process, you can dive right in without any hassle.",
};

const becameAHost = {
  title: "Become a Host",
  description:
    "Embrace the opportunity to become a car rental host and turn your vehicle into a valuable asset. Odpo offers a straightforward, seamless and rewarding experience for car owners who want to share their cars with others, making it a simple way to earn extra income.",
};
const defaultTheme = createTheme();

const Item = styled(Paper)(({ theme }) => ({

  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F6F6F6",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height:`${useMediaQuery("(max-width: 767px)")? "125px":'150px'}`,
  width: `${useMediaQuery("(max-width: 767px)")? "125px":'150px'}`,
  justifyContent: "space-between",
}));



const HomePage = () => {
  const isMobileView = useMediaQuery("(max-width: 767px)")
  const customePadding = isMobileView ? "2%" : "6%"
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    // Navigate to the BecomeAHost page
    navigate('/become-a-host');
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const items = [
    {
      id: 1,
      textTitle: "Download app",
      textSubtitle: "Available in Apple Store and Google Market",
      imageUrl: `${download}`,
    },
    {
      id: 2,
      textTitle: "Create account",
      textSubtitle: "Connect through your socials",
      imageUrl: `${createAccount}`,
    },
    {
      id: 3,
      textTitle: "Explore",
      textSubtitle: "For your Journey, select the perfect vehicle",
      imageUrl: `${explore}`,
    },
    {
      id: 4,
      textTitle: "Drive",
      textSubtitle: "Reserve, Drive and Enjoy",
      imageUrl: `${drive}`,
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        maxWidth={false}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <MainFeaturedPost post={mainFeaturedPost} />
        <main
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {/* Main Header */}
          <div id="main_heade"
            style={{
              padding:customePadding,
            }}
          >
            <div id={"Heading_text"}
              style={{
                paddingLeft: customePadding,
                paddingRight: customePadding,
              }}
            >
              {isMobileView ? (
                <CustomWidgetForMobile
                  title={titleText.title}
                  subtitle1={titleText.description}
                />
              ) : (
                <CustomWidgetForHeader
                  title={titleText.title}
                  subtitle1={titleText.description}
                  alignText="center"
                />
              )}
            </div>
            <div id={"information_related_to_easy_steps"} style={{ paddingTop:!isMobileView?"6%":"1%",paddingBottom: 20 }}>
              {isMobileView ? (
                <CustomWidgetForMobile
                  title={infoText.title}
                  subtitle2={infoText.description}
                />
              ) : (
                <CustomWidgetForContent
                  title={infoText.title}
                  subtitle1={infoText.description}
                />
              )}
            </div>

            <div id={"grid_implement"} style={{ paddingBottom: isMobileView ? "45px" : "95px" }}>
              <Grid
                container
               
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 6 }}
                justifyContent={isMobileView ? "center" : "flex-start"}
              >
                {items.map((item) => (
                  <Grid key={item.id} item xs={6} sm={3}>
                    <Item
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          
                          
                        }}
                      >
                        <img
                          style={{ height: "100px" }}
                          src={`${item.imageUrl}`}
                          alt={`${item.id}`}
                          loading="lazy"
                        />
                      </div>
                    </Item>
                    <Typography
                      variant={isMobileView ? "h6" : "h5"}
                      component="div"
                      mt={2}
                      fontWeight={600}
                    >
                      {item.textTitle}
                    </Typography>
                    <Typography
                      variant={isMobileView ? "body2" : "h6"}
                      component="div"
                      mt={1}
                    >
                      {item.textSubtitle}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          {/*TODO: image slide need to fix in the mobile view----------------------*/}
        
          <CardDataListForWeb />

          {isMobileView ? (
            <div id={"became_host_mobile"}
              style={{
                padding: "4%",
                paddingBottom: "6%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={becameahost}
                alt={"not loading"}
                loading="lazy"
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <div style={{ paddingLeft: "1%" }}>
                <CustomWidgetForMobile
                  title={becameAHost.title}
                  subtitle1={becameAHost.description}
                />
                <div style={{ paddingTop: "3%" }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#12229F",
                      color: "white",
                      '&:hover': {
                        backgroundColor: '#12229F',
                      },
                    }}
                    onClick={handleLearnMoreClick}
                  >
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
            id={"became_host_web"}
            style={{ padding: "4.5%", paddingTop:"10%",  paddingBottom: !isMobileView?"9%":"8%",display: "flex", alignItems: "center" }}
          >
            <img
              src={becameahost}
              alt={"not loading"}
              loading="lazy"
              style={{
                width: "50%",
                "@media (max-width: 300px)": { width: "100%" },
              }}
            />
            <div style={{ paddingLeft: "4%" }}>
              <div
                style={{
                  width: "100%",
                  padding: "1rem",
                  "@media (max-width: 500px)": {
                    width: "1000%",
                  },
                }}
              >
                <CustomWidgetForContent
                title={becameAHost.title}
                subtitle1={becameAHost.description}
              />

                {/* <Typography
                  sx={{ p: "auto",  fontWeight:"500",fontSize:"48px" }}
                  gutterBottom
                >
                  {connectWitUS.title}
                </Typography>
                <Typography
                  variant={"h5"}
                  paragraph
                >
                  {connectWitUS.description}
                </Typography> */}
              </div>
              <div style={{ paddingTop:"4%", paddingBottom: "2.5%" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#12229F",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#D92429",
                    },
                  }}
                  onClick={handleLearnMoreClick}
                >
                  Learn More

                </Button>
              </div>
            </div>
          </div>

          )}
        </main>
      </Container>
    </ThemeProvider>
  )
}

export default HomePage
