import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Quick Start",
};

const defaultTheme = createTheme();

const QuickStartGuide = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);

  const isMobile = useMediaQuery("(max-width: 600px)"); 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding:"4%" }}>
        {/* Quick Start Guide */}
        <section id="QuickStartGuide">
          <Box mt={4} mb={1}>
           { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}

            <ContentTitleWithLine text="Quick Start" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" ,}}
            >
              <p>Start by creating an account</p>
              <ol type="1">
                <li>
                  Click on “sign up” from account page and provide all requested
                  details.
                </li>
                <li>
                  Post successful signup, sign in using appropriate credentials.
                </li>
                <li>
                  You can see your DL approval status on your account page.
                </li>
                <li>
                  Once your DL is verified, you can reserve a vehicle and start
                  driving.
                </li>
                <li>
                  Choose your location and search for a car. You can adjust the
                  reservation dates and times to suit your plans.
                </li>
                <li>
                  Read the listing for the complete vehicle description and any
                  guidelines After selecting vehicle to reserve, reserve vehicle
                  using “Checkout“ option.
                </li>
                <li>
                  Choose a Insurance plan for your reservation. Higher the plan
                  cost, the less you’d have to pay out-of-pocket if there’s
                  vehicle damage during the reservation.
                </li>
                <li>
                  Review the full breakdown of costs for your reservation before
                  booking or submitting your booking request.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default QuickStartGuide;
