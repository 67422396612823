import React from "react";
import { Typography, ThemeProvider, createTheme } from "@mui/material";

// Define a custom theme
const theme = createTheme();

const CustomWidgetForContent = ({ title, subtitle1, subtitle2,titleColor,subtitleColor,subtitleColor2, textAline1,textAline2,textAline3}) => {

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography style={{color:titleColor||"black",}} fontSize={'3rem'} mt={4} align={textAline1} paragraph>
          {title}
        </Typography>
        {subtitle1 && (<Typography style={{color:subtitleColor||"black",fontSize:'1.5rem'}} mt={2} align={textAline2} paragraph>
          {subtitle1}
        </Typography>)}
        {subtitle2 && (
          <Typography style={{color:subtitleColor2||"black",fontSize:'1.5rem'}} mt={2} align={textAline3} paragraph>
            {subtitle2}
          </Typography>
        )}
      </ThemeProvider>
    </div>
  );
};

export default CustomWidgetForContent;
