import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Vehicle Policies",
};

const defaultTheme = createTheme();

const HostVehiclePolicies = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/*Vehicle tracking and technology policy*/}
        <section id="VehicleTrackingAndTechnologyPolicy">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
      
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Vehicle tracking and technology policy" />
            {/* Todo click here need to implement */}
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                To understand our policies regarding vehicle tracking and
                technology, please click here to read our Privacy Policy.{" "}
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};
export default HostVehiclePolicies;
