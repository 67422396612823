import React from "react";
import { Typography, ThemeProvider, createTheme, Grid } from "@mui/material";

// Define a custom theme
const theme = createTheme();

const ContentInLeftImageRightWeb = ({
  title,
  subtitle1,
  subtitle2,
  titleColor,
  subtitleColor,
  subtitleColor2,
  textAline1,
  textAline2,
  textAline3,
  imgSource,
}) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
      <Grid
            container
            style={{
              paddingTop: "8%",
              paddingBottom: "4%",
              display: "flex",
              alignItems: "start",
              justifyItems: "center",
            }}
          >
            <Grid item xs={12} sm={6}>
              <div style={{ paddingRight: "4%" }}>
              <Typography
            style={{ color: titleColor || "black" }}
            fontSize={"3rem"}
            mt={4}
            align={textAline1}
            paragraph
          >
            {title}
          </Typography>
          {subtitle1 && (
            <Typography
              style={{ color: subtitleColor || "black", fontSize: "1.5rem" }}
              mt={2}
              align={textAline2}
              paragraph
            >
              {subtitle1}
            </Typography>
          )}
          {subtitle2 && (
            <Typography
              style={{ color: subtitleColor2 || "black", fontSize: "1.5rem" }}
              mt={2}
              align={textAline3}
              paragraph
            >
              {subtitle2}
            </Typography>
          )}
               
              </div>
            </Grid>
        
        <Grid item xs={12} sm={6}>
          <img
            src={imgSource}
            alt="not loading"
            style={{ maxWidth: "100%", height: "auto" }}
            loading="lazy"
          />
        </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default ContentInLeftImageRightWeb;
