import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "ODPO Vehicle Driver Eligibility",
};

const defaultTheme = createTheme();

const DriverEligibility = () => {
  useEffect(() => {
    const section = window.location.hash;
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Age Requirements */}
        <section id="AgeRequirements">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Age Requirements" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem",}}
            >
              <p>
                To reserve and drive a car through Odpo, you need to meet age
                requirements and have a valid driver's license. For detailed
                eligibility criteria, please refer to our Eligibility
                Requirements Document.
              </p>
            </Typography>
          </Box>
        </section>

        {/* Driving Licence Verification */}
        <section id="LicenceVerification">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text=" Driving Licence Verification" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                To ensure a seamless experience, users are required to verify
                their driving licence (DL) when using our application. Please
                keep your original driving licence handy before starting the
                verification process.
              </p>
              <p>Here's how you can verify your DL:</p>
              <ol type="1">
                <li>Open the application and navigate to the "Profile" tab.</li>
                <li>
                  Click on "License Details" and then on the "Update License"
                  button in next screen.
                </li>
                <li>Follow the instructions to verify your driving license.</li>
                <li>
                  Your license will be approved within 24-78 hours, and the
                  status will change to "Verified".
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default DriverEligibility;
