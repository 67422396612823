import { Routes, Route } from "react-router-dom"
import HomePage from '../HomePage'
import HowItWorks from '../HowItWorks'
import AboutUs from '../AboutUs'
import Faqs  from "../FAQS"
import BecomeAHost from '../BecomeAHost'

import { MenuBar, Footer } from "../../components/MenuAndFotter"
import GettingStarted from "../FAQS/components/Drive/GettingStarted"
import VehicleReservations from "../FAQS/components/Drive/VehicleReservations"
import ReservationPayment from "../FAQS/components/Drive/ReservationPayment"
import DriverEligibility from "../FAQS/components/Drive/DriverEligibility"
import ModifyingCancelingReservation from "../FAQS/components/Drive/ModifyingCancelingReservation"
import QuickStartGuide from "../FAQS/components/Drive/QuickStart"
import DriverResponsibilities from "../FAQS/components/Drive/DriverResponsibilities"
import HostQuickStart from "../FAQS/components/Host/HostQuickStart"
import HostAccountManagement from "../FAQS/components/Host/HostAccountManagement"
import HostPatners from "../FAQS/components/Host/HostPartners"
import HostGettingStarted from "../FAQS/components/Host/HostGettingStarted"
import HostPricingYourVehicle from "../FAQS/components/Host/HostPricingVehicle"
import HostSettingsAndOptions from "../FAQS/components/Host/HostSettingsAndOptions"
import HostPaymentsAndPayouts from "../FAQS/components/Host/HostPaymentsAndPayouts"
import HostManagingYourVehicle from "../FAQS/components/Host/HostManagingYourVehicle"
import HostMaintainingYourVehicle from "../FAQS/components/Host/HostMaintainingYourVehicle"
import HostSafetyProtocols from "../FAQS/components/Host/HostSafetyProtocols"
import HostVehiclePolicies from "../FAQS/components/Host/HostVehiclePolicies"
import HostManagingVehicleDamage from "../FAQS/components/Host/HostManagingVehicleDamage"
import HostModifyingOrCancelingTrips from "../FAQS/components/Host/HostModifyingOrCancelingReservations"
import HostManagingReservations from "../FAQS/components/Host/HostManagingReservations"

import PayoutSetupComplete from "./dynamic/PayoutSetupComplete"

import { useLocation } from 'react-router-dom'

const usePath = () => {
    return useLocation().pathname
}

const MyBrowserRouter = () => {

    const path = usePath()

    if(path === "/payout-setup-complete") {
        return (
            <PayoutSetupComplete />
        )
    }

    return (
        <div>
            {/*menu bar*/}
            <MenuBar />
            <div style={{marginTop: "64px"}} >
                <Routes >
                    <Route path="/" element={<HomePage />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/faqs" element={<Faqs/>}/>
                     {/* Drive FAQs */}
                    <Route path="/faqs/drive/QuickStartGuide" element={<QuickStartGuide />} />
                    <Route path="/faqs/drive/GettingStarted" element={<GettingStarted />} />
                    <Route path="/faqs/drive/DriverEligibility" element={<DriverEligibility />} />
                    <Route path="/faqs/drive/VehicleReservations" element={<VehicleReservations />} />
                    <Route path="/faqs/drive/ReservationPayment" element={<ReservationPayment />} />
                    <Route path="/faqs/drive/ModifyingCancelingReservation" element={<ModifyingCancelingReservation />} />
                    <Route path="/faqs/drive/DriverResponsibilities" element={<DriverResponsibilities />} />

                     {/* Host FAQs */}
                    <Route path="/faqs/host/HostQuickStart" element={<HostQuickStart />} />
                    <Route path="/faqs/host/HostAccountManagement" element={<HostAccountManagement />} />
                    <Route path="/faqs/host/HostPatners" element={<HostPatners />} />
                    <Route path="/faqs/host/HostGettingStarted" element={<HostGettingStarted />} />
                    <Route path="/faqs/host/HostPricingYourVehicle" element={<HostPricingYourVehicle />} />
                    <Route path="/faqs/host/HostSettingsAndOptions" element={<HostSettingsAndOptions />} />
                    <Route path="/faqs/host/HostPaymentsAndPayouts" element={<HostPaymentsAndPayouts />} />
                    <Route path="/faqs/host/HostManagingReservations" element={<HostManagingReservations />} />
                    <Route path="/faqs/host/HostManagingYourVehicle" element={<HostManagingYourVehicle />} />
                    <Route path="/faqs/host/HostModifyingOrCancelingTrips" element={<HostModifyingOrCancelingTrips/>} />
                    <Route path="/faqs/host/HostMaintainingYourVehicle" element={<HostMaintainingYourVehicle/>} />
                    <Route path="/faqs/host/HostSafetyProtocols" element={<HostSafetyProtocols/>} />
                    <Route path="/faqs/host/HostVehiclePolicies" element={<HostVehiclePolicies/>} />
                    <Route path="/faqs/host/HostManagingVehicleDamage" element={<HostManagingVehicleDamage/>} />

                    <Route path="/become-a-host" element={<BecomeAHost />} />
                </Routes>
            </div>

            {/*Footer*/}
            <Footer />
        </div>
    )

}

export default MyBrowserRouter