import React, { useState, useEffect} from "react";
import {
  useMediaQuery,
  ThemeProvider,
  createTheme,
  Container,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CustomWidgetForHeader from "../../components/WebComponents/CustomTextForHeader";
import CustomWidgetForContent from "../../components/WebComponents/CustomTextForContent";
import ContentInLeftImageRightWeb from "../../components/WebComponents/ContentInLeftImageRightWeb";
import wideImage from "../../assets/Background/HowItWorksScreen/widerange.jpeg";
import transportationImage from "../../assets/Background/HowItWorksScreen/tranpirentprice.png";
import locationImage from "../../assets/Background/HowItWorksScreen/multilocation.png";
import FlexibleImageCard from "../../components/FlexibleImageCard";
import peaceImage from "../../assets/Background/HowItWorksScreen/peaceofmind.png";
import wayImage from "../../assets/Background/HowItWorksScreen/way.png";
import exactImage from "../../assets/Background/HowItWorksScreen/exact.png";
import budgetImage from "../../assets/Background/HowItWorksScreen/allBudget.png";
import hassleImage from "../../assets/Background/HowItWorksScreen/hassle.png";
import aroundImage from "../../assets/Background/HowItWorksScreen/around.png";
import crewImage from "../../assets/Background/HowItWorksScreen/crew.png";
import rideImage from "../../assets/Background/HowItWorksScreen/ride.png";
import managementImage from "../../assets/Background/HowItWorksScreen/vh_management.png";
import stationImage from "../../assets/Background/HowItWorksScreen/stations.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomWidgetForMob from "../../components/MobComponents/CustomTextForMob";
import ContentTopImageBelowMob from "../../components/MobComponents/ContentTopImageBelow";

const defaultTheme = createTheme();

const titleText = {
  title: "How Odpo works",
  description:
    "Odpo operates as a peer-to-peer car-sharing platform that provides a simple and innovative way to connect vehicle owners with individuals in need of temporary transportation. To get started, car owners can list their vehicles on the Odpo platform, offering detailed descriptions, rental rates, and availability information. Prospective renters can then browse through a diverse selection of cars, which includes options ranging from economy vehicles to high-end luxury vehicles.",
};
const treatingGuestsImages = [
  {
    id:1,
    imageUrl: `${exactImage}`,
    title: "Your Exact Vehicle Match",
    description: "Handpick the perfect vehicle to meet your exact demands",
   
  },
  {
    id:2,
    imageUrl: `${budgetImage}`,
    title: "All Budgets, All Preferences",
    description:
      "Whether you prefer economy or luxury, our vehicles for all budgets and preferences",
  },
  {
    id:3,
    imageUrl: `${hassleImage}`,
    title: "Hassle-Free Access Points",
    description:
      "Access vehicles with ease, thanks to conveniently situated locations",
  },
  {
    id:4,
    imageUrl: `${aroundImage}`,
    title: "Support When You Need It",
    description:
      "Rely on our dedicated vehicle hosts for unwavering assistance whenever you require it",
  },
];
const experienceVIPImages = [
  {
    id: 1,
    imageUrl: `${crewImage}`,
    title: "Your Crew, Your access",
    description:
      "Secure your fleet by granting access to authorized personnel only, guaranteeing efficient and controlled usage.",
  },
  {
    id: 2,
    imageUrl: `${rideImage}`,
    title: "Your Ride, Your Rates",
    description:
      "Create flexible pricing plans tailored to your customers and apply them seamlessly to your selected vehicles.",
  },
  {
    id: 3,
    imageUrl: `${managementImage}`,
    title: "Quick Vehicle Management",
    description:
      "Pause your listing for reservations or remove it permanently, as per your convenience.",
  },
  {
    id: 4,
    imageUrl: `${stationImage}`,
    title: "Local Stations",
    description:
      "Create your vehicle stations your way, from cozy home spots to exciting locales at your discretion",
  },
];

const HowItWorks = () => {
  const isMobileView = useMediaQuery("(max-width: 767px)");
  const customePadding = isMobileView ? "2%" : "8%";

  const [currentImageIndex1, setCurrentImageIndex1] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex1(
      (prevIndex) =>
        (prevIndex - 1 + treatingGuestsImages.length) %
        treatingGuestsImages.length
    );
  };
  const handlePrevClick2 = () => {
    setCurrentImageIndex2(
      (prevIndex) =>
        (prevIndex - 1 + experienceVIPImages.length) %
        experienceVIPImages.length
    );
  };
  const handleNextClick = () => {
    setCurrentImageIndex1(
      (prevIndex) => (prevIndex + 1) % treatingGuestsImages.length
    );
  };
  const handleNextClick2 = () => {
    setCurrentImageIndex2(
      (prevIndex) => (prevIndex + 1) % experienceVIPImages.length
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <ThemeProvider theme={defaultTheme}>
      {isMobileView ? (
        <Container style={{ maxWidth: "100%", padding: "4%" }}>
          <div
            id="mob_How_Odpo_works"
            style={{
              paddingLeft: customePadding,
              paddingRight: customePadding,
            }}
          >
            <CustomWidgetForMob
              title={titleText.title}
              subtitle1={titleText.description}
              titileFont={"2.5rem"}
            />
          </div>
          <div id="mob_Three_images_with_overlay_text">
            <Grid
              container
              style={{ paddingTop: "4%", paddingBottom: "2%" }}
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <FlexibleImageCard
                  imageUrl={wideImage}
                  title="Wide Range Vehicle Collection"
                  description="Access to a diverse variety of vehicles to choose from"
                  CardHeight={280}
                  CardWidth={450}
                  ImageHeight={140}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FlexibleImageCard
                  imageUrl={locationImage}
                  title="Multiple Locations"
                  description="Ability to pick-up and drop-off your vehicle as per your convenience"
                  CardHeight={280}
                  CardWidth={450}
                  ImageHeight={140}
               />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FlexibleImageCard
                  imageUrl={transportationImage}
                  title="Transparent Pricing"
                  description="Visibility on rates of multiple vehicles to reserve a vehicle based on your budget"
                  CardHeight={280}
                  CardWidth={450}
                  ImageHeight={140}
               />
              </Grid>
            </Grid>
          </div>
          <div id="Web_Left_Text_right_image">
          <ContentTopImageBelowMob
               title={"Discover Peace of Mind with Odpo"}
               subtitle1={" Odpo stands out as a car-sharing platform that goes beyond providing transportation; it offers a unique sense of peace and assurance to both car owners and renters alike. Furthermore, Odpo promotes a culture of trust and respect within its community, fostering a sense of ease and mutual understanding between owners and renters. Transparent communication and fair pricing create a balanced car-sharing experience in which everybody can feel comfortable and satisfied with the rental process."}
             imgSource={peaceImage}/>
          </div>
          <div
            id="Web_Left_Text_right_description"
            style={{ paddingBottom: "8%" }}
          >
            
            <ContentTopImageBelowMob
                 title={"Treating guests the Odpo way"}
                 subtitle1={
                   "Odpo takes immense pride in creating a welcoming environment for guests, ensuring that their experiences are exceptionally memorable and thoroughly enjoyable. From the moment guests sign up for this platform to the final return of the car, we prioritize transparency, clear communication, and unwavering responsiveness."
                 
                 }       imgSource={wayImage}/>
          </div>
          <div>
           

            {/* TODO: as per Ui/Ux arows icons need to shown, */}
            <div style={{ flexDirection: "column", alignItems: "flex-end" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton onClick={handlePrevClick}>
                  <ArrowBackIcon />
                </IconButton>
                <IconButton onClick={handleNextClick}>
                  <ArrowForwardIcon />
                </IconButton>
              </div>
              <div>
                <FlexibleImageCard
                 key={treatingGuestsImages[currentImageIndex1].id}
                  imageUrl={treatingGuestsImages[currentImageIndex1]?.imageUrl}
                  title={treatingGuestsImages[currentImageIndex1]?.title}
                  description={
                    treatingGuestsImages[currentImageIndex1]?.description
                  }
                  CardHeight={"42vh"}
                  ImageHeight={"120px"}
                  
                />
              </div>
            </div>
          </div>
          <div
            id="Web_Experience_the_VIP"
            style={{
              paddingLeft: customePadding,
              paddingRight: customePadding,
            }}
          >
            <CustomWidgetForMob
              title={"Experience the VIP Treatment with Odpo Hosting"}
              subtitle1={
                "Odpo Hosting takes the car-sharing experience to a whole new level, offering hosts the real VIP treatment. Hosts in Odpo are more than just car owners; they are curators of unforgettable journeys and partners in a lifetime experience. From the moment guests browse your handpicked selection of vehicles, they step into a world of personalized service and attention to detail. Odpo hosts take pride in presenting their cars immaculately, ensuring that each vehicle's maintenance and cleanliness meets the highest standards. Hosting in Odpo is a lifetime experience, where hosts and guests come together as partners in creating memorable moments on the road."
              }
            />
          </div>
          {/* TODO: as per Ui/Ux arows icons need to shown, */}
          <div
            id="final_list_images"
            style={{ flexDirection: "column", alignItems: "flex-end" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <IconButton onClick={handlePrevClick2}>
                <ArrowBackIcon />
              </IconButton>
              <IconButton onClick={handleNextClick2}>
                <ArrowForwardIcon />
              </IconButton>
            </div>
            <div>
              <FlexibleImageCard
              key={experienceVIPImages[currentImageIndex2].id}
                imageUrl={experienceVIPImages[currentImageIndex2]?.imageUrl}
                title={experienceVIPImages[currentImageIndex2]?.title}
                description={
                  experienceVIPImages[currentImageIndex2]?.description
                }
                CardHeight="52vh"
                ImageHeight="250vh"
              />
            </div>
          </div>
        </Container>
      ) : (
        <Container style={{ maxWidth: "100%", padding: "4%" }}>
          <div
            id="Web_How_Odpo_works"
            style={{
              paddingLeft: customePadding,
              paddingRight: customePadding,
            }}
          >
            <CustomWidgetForHeader
              title={titleText.title}
              subtitle1={titleText.description}
              
            />
          </div>
          <div id="Web_Three_images_with_overlay_text">
            <Grid
              container
              style={{ paddingTop: "4%", paddingBottom: "2%" }}
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <FlexibleImageCard
                  imageUrl={wideImage}
                  title="Wide Range Vehicle Collection"
                  description="Access to a diverse variety of vehicles to choose from"
                  CardHeight={300}
                  CardMaxWidth={320}
                  ImageHeight={140}
               />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FlexibleImageCard
                  imageUrl={locationImage}
                  title="Multiple Locations"
                  description="Ability to pick-up and drop-off your vehicle as per your convenience"
                  CardHeight={300}
                  CardMaxWidth={320}
                  ImageHeight={140}
                  
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FlexibleImageCard
                  imageUrl={transportationImage}
                  title="Transparent Pricing"
                  description="Visibility on rates of multiple vehicles to reserve a vehicle based on your budget"
                  CardHeight={300}
                  CardMaxWidth={320}
                  ImageHeight={140}
        
                />
              </Grid>
            </Grid>
          </div>
          <div id="Web_Left_Text_right_image">
             <ContentInLeftImageRightWeb
               title={"Discover Peace of Mind with Odpo"}
               subtitle1={" Odpo stands out as a car-sharing platform that goes beyond providing transportation; it offers a unique sense of peace and assurance to both car owners and renters alike. Furthermore, Odpo promotes a culture of trust and respect within its community, fostering a sense of ease and mutual understanding between owners and renters. Transparent communication and fair pricing create a balanced car-sharing experience in which everybody can feel comfortable and satisfied with the rental process."}
             imgSource={peaceImage}/>
          </div>
          <div id="Web_Left_Text_right_description">
            <Grid
              container
              style={{
                paddingTop: "2.5%",
                paddingBottom: "2.5%",
                display: "flex",
                alignItems: "start",
                justifyItems: "start",
              }}
            >
              <Grid item xs={12} sm={6}>
                <div style={{ paddingRight: "8%" }}>
                  <Typography  style={{ paddingTop:"2.6%",paddingBottom: "2.6%",fontSize:"3rem" }}>
                    Treating guests the Odpo way
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography fontSize={"1.5rem"} paragraph>
                Odpo takes immense pride in creating a welcoming environment for guests, ensuring that their experiences are exceptionally memorable and thoroughly enjoyable. From the moment guests sign up for this platform to the final return of the car, we prioritize transparency, clear communication, and unwavering responsiveness.
                </Typography>
              </Grid>
            </Grid>
          </div>
          {/* //TODO: I implemented a grid, but when I decreased the width of the web, it overlaid on the others*/}
          <div id="Grid_images">
            <Grid container spacing={12} style={{paddingBottom:"8%"}}>
              <Grid item xs={12} sm={4}>
                <img src={wayImage} alt="Large" style={{ height: "81vh" }} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FlexibleImageCard
                      imageUrl={exactImage}
                      title="Your Exact Vehicle Match"
                      description="Handpick the perfect vehicle to meet your exact demands"
                      CardHeight={250}
                      CardMaxWidth={320}
                      ImageHeight={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FlexibleImageCard
                      imageUrl={budgetImage}
                      title="All Budgets, All Preferences"
                      description="Whether you prefer economy or luxury, our vehicles for all budgets and preferences"
                      CardHeight={250}
                      CardMaxWidth={320}
                      ImageHeight={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FlexibleImageCard
                      imageUrl={hassleImage}
                      title="Hassle-Free Access Points"
                      description="Access vehicles with ease, thanks to conveniently situated locations"
                      CardHeight={250}
                      CardMaxWidth={320}
                      ImageHeight={100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FlexibleImageCard
                      imageUrl={aroundImage}
                      title="Support When You Need It"
                      description="Rely on our dedicated vehicle hosts for unwavering assistance whenever you require it"
                      CardHeight={250}
                      CardMaxWidth={320}
                      ImageHeight={100}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div
            id="Web_Experience_the_VIP"
            style={{
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <CustomWidgetForContent
              title={"Experience the VIP Treatment with Odpo Hosting"}
              subtitle1={
                "Odpo Hosting takes the car-sharing experience to a whole new level, offering hosts the real VIP treatment. Hosts in Odpo are more than just car owners; they are curators of unforgettable journeys and partners in a lifetime experience. From the moment guests browse your handpicked selection of vehicles, they step into a world of personalized service and attention to detail. Odpo hosts take pride in presenting their cars immaculately, ensuring that each vehicle's maintenance and cleanliness meets the highest standards. Hosting in Odpo is a lifetime experience, where hosts and guests come together as partners in creating memorable moments on the road."
              }
              textAline1={'center'}
              textAline2={"center"}
            />
          </div>
          <div id="final_list_images">
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                width: "100%",
                padding: "10px",
              }}
            >
              {experienceVIPImages.map((image, index) => (
                <FlexibleImageCard
                  key={image.id}
                  imageUrl={image.imageUrl}
                  title={image.title}
                  description={image.description}
                  CardHeight={580}
                  CardMaxWidth={320}
                  ImageHeight={330}
                />
              ))}
            </div>
          </div>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default HowItWorks;
