import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid,
  Button,
  Typography,
  Divider,
  Link,
  useMediaQuery,
  IconButton,
Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import AccordionGroup from "@mui/joy/AccordionGroup";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "../../assets/CompanyLogo/odpo_logo.png";

const Footer = () => {
  const isMobileView = useMediaQuery("(max-width: 767px)");
  const [index, setIndex] = React.useState(0);
const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const textButtonStyle2 = {
    textTransform: "none",
    color: "#343434",
    justifyContent: "left",
    padding: "0", // Remove padding
    textAlign: "left",
  };
  const textButtonStyle1 = {
    textTransform: "none",
    color: "#343434",
   alignItems: "flex-start",
    padding: "0",
    display: "flex",
    flexDirection: "column",
  };
const navigate = useNavigate();
  const handleHomeClick = (path) => {
    navigate("/");
  };

  const handleAboutUsClick = (path) => {
    navigate("/about-us");
  };

  const handleHowItWorksClick = (path) => {
    navigate("/how-it-works");
  };

  const handleFAQSClick = (path) => {
    console.log("faq tap");
    navigate("/faqs");
  };

  const handleBecomeAHostClick = (path) => {
    navigate("/become-a-host");
  };

  const handleInsuranceClick = () => {
    setDialogTitle("Insurance");
    setOpenDialog(true);
  };

  const handlePrivacyPolicyClick = () => {
    setDialogTitle("Privacy Policy");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
    <footer>
      {isMobileView ? (
        <Container
          style={{
            background: "#F6F6F6",
            width: "100%",
            // height: "80vh",
            padding: "4%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around", 
          }}
        >
          <Grid item xs={12} style={{ textAlign: "left", paddingTop: "4%" }}>
            <Typography variant="h4" style={{ fontSize: "1.5rem" }}>
              Let us take great care of you
            </Typography>
            <div style={{ paddingTop: "3%" }}>
              <Button
                variant="contained"
                sx={{
                      backgroundColor: "#12229F",
                      textTransform: "capitalize",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#12229F", 
                      },
                    }}
              >
                Download Drive App
              </Button>
            </div>
            <div style={{ paddingTop: "3%" }}>
            <Button
                variant="outlined"
                sx={{
                  color: "#12229F",
                  textTransform: "capitalize",
                  borderColor: "#12229F",
                  "&:hover": {
                    borderColor: "#12229F",
                  },
                }}
              >
                Download Host App
              </Button>
            </div>
          </Grid>
          <Divider style={{ margin: "20px 0" }} />
          {/* Expandable List */}
          <AccordionGroup>
            <Accordion
              expanded={index === 0}
              onChange={(_event, expanded) => {
                setIndex(expanded ? 0 : null);
              }}
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Navigation
              </AccordionSummary>
              <AccordionDetails>
                <Button sx={textButtonStyle1} onClick={handleHomeClick}>
                  Home
                </Button>
                <Button sx={textButtonStyle1} onClick={handleAboutUsClick}>
                  About Us
                </Button>
                <Button sx={textButtonStyle1} onClick={handleHowItWorksClick}>
                  How it works
                </Button>
                {/* <Button sx={textButtonStyle1}>Blog</Button> */}
                <Button sx={textButtonStyle1} onClick={handleFAQSClick}>
                  FAQS
                </Button>
                <Button sx={textButtonStyle1} onClick={handleBecomeAHostClick}>
                  Become a host
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={index === 1}
              onChange={(event, expanded) => {
                setIndex(expanded ? 1 : null);
              }}
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                What We Do
              </AccordionSummary>
              <AccordionDetails>
                <Button sx={textButtonStyle1} onClick={handleBecomeAHostClick}>
                  Platform to host vehicle
                </Button>
                <Button sx={textButtonStyle1} onClick={handleHowItWorksClick}>
                  Platform to reserve vehicle
                </Button>
                {/* <Button sx={textButtonStyle1}>Book a car</Button>
                <Button sx={textButtonStyle1}>Trust & safety</Button>
                <Button sx={textButtonStyle1}>Sustainability</Button> */}
                <Button sx={textButtonStyle1}onClick={handleInsuranceClick} >Insurance</Button>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={index === 2}
              onChange={(event, expanded) => {
                setIndex(expanded ? 2 : null);
              }}
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Legal
              </AccordionSummary>
              <AccordionDetails>
                <Button sx={textButtonStyle1} onClick={handlePrivacyPolicyClick} >Privacy policy</Button>
                {/* <Button sx={textButtonStyle1}>Terms of use service</Button> */}
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={index === 3}
              onChange={(event, expanded) => {
                setIndex(expanded ? 3 : null);
              }}
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Contact us
              </AccordionSummary>
              <AccordionDetails>
                <Button sx={textButtonStyle1}>
                  4575 Webb Bridge Road Alpharetta, Georgia 30005 #4828.
                </Button>
                <Button sx={textButtonStyle1}> contact@odpo.io</Button>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>

          <Divider style={{ margin: "20px 0" }} />
          {/* Row with Copyright and Links */}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "4%",
            }}
          >
            <Typography variant="subtitle1">©2023 Odpo</Typography>
            <Typography variant="subtitle1">
              <Link href="#">Terms</Link> | <Link href="#">Privacy</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle1">
              <Link href="#">Cookies Policy</Link>
            </Typography>
            <Typography variant="subtitle1">
              <Link href="#">Cookies Setting</Link>
            </Typography>
          </Grid>
        </Container>
      ) : (
        <Container
          style={{
            background: "#F6F6F6",
            maxWidth: "100%",
            height: "Auto",
            padding: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            style={{ lineHeight: "1.5" }}
            mt={4}
          >
            Let us take <br /> great care of you
          </Typography>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{ marginTop: "20px" }}
          >
            <Grid item>
              <Button
                variant="contained"
                sx={{
                      backgroundColor: "#D92429",
                      textTransform: "capitalize",
                  //borderWidth: 2.5,
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#D92429", // Disable hover effect
                      },
                    }}
              >
                 Download Drive App
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                  backgroundColor: "#12229F",
                 // borderWidth: 2.5,
                  "&:hover": {
                    backgroundColor: "#12229F",
                  },
                }}
              >
                Download Host App
              </Button>
            </Grid>
          </Grid>
          {/* <Divider style={{ margin: "20px 0" }} /> */}
          <Grid container spacing={2} sx={{ padding: "65px" }}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Navigation
              </Typography>
              <Grid container direction="column" justifyContent={"left"}>
                <Button sx={textButtonStyle2} onClick={handleHomeClick}>
                  Home
                </Button>
                <Button sx={textButtonStyle2} onClick={handleHowItWorksClick}>
                  How it works
                </Button>
                <Button sx={textButtonStyle2} onClick={handleAboutUsClick}>
                  About us
                </Button>
                {/* <Button sx={textButtonStyle2}>Blog</Button> */}
                <Button sx={textButtonStyle2} onClick={handleFAQSClick}>
                  FAQS
                </Button>
                <Button sx={textButtonStyle2} onClick={handleBecomeAHostClick}>
                  Become a host
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {" "}
                What We Do
              </Typography>
              <Grid container direction="column">
                <Button sx={textButtonStyle1} onClick={handleBecomeAHostClick}>
                  Platform to host vehicle
                </Button>
                <Button sx={textButtonStyle1} onClick={handleHowItWorksClick}>
                  Platform to reserve vehicle
                </Button>
                {/* <Button sx={textButtonStyle2}>Book a car</Button>
                <Button sx={textButtonStyle2}>Trust & safety</Button>
                <Button sx={textButtonStyle2}>Sustainability</Button> */}
                <Button sx={textButtonStyle2} onClick={handleInsuranceClick}>Insurance</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                LEGAL
              </Typography>
              <Grid container direction="column">
                <Button sx={textButtonStyle2} onClick={handlePrivacyPolicyClick} >Privacy policy</Button>
                {/* <Button sx={textButtonStyle2}>Terms of use service</Button> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                CONTACT US
              </Typography>
              <Grid container direction="column">
                <Button sx={textButtonStyle2}>
                  4575 Webb Bridge Road <br />
                  Alpharetta, GA 30005 #4828.
                </Button>
                <Button sx={textButtonStyle2}> contact@odpo.io</Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: "20px 0" }} />
          <div style={{ paddingBottom: "20px", display: "flex" }}>
            <Grid container justifyContent="space-around" alignItems="center">
              <img
                src={logo}
                alt="Logo"
                style={{ width: "100px", height: "auto" }}
              />
              <Typography variant="subtitle1" align="center">
                © 2023 Odpo LLC. All Rights Reserved.
              </Typography>
              <div>
                <IconButton>
                  <FacebookIcon />
                </IconButton>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>
                <IconButton>
                  <TwitterIcon />
                </IconButton>
              </div>
            </Grid>
          </div>
        </Container>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          "Use {dialogTitle} service?"
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Sorry for the inconvenience! This section is currently under construction.
          We're working diligently to bring you an improved experience. 
          Stay tuned for updates!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            Okey
          </Button>
        </DialogActions>
      </Dialog>

    </footer>
  );
};

export default Footer;
