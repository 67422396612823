import React, { useState,useEffect} from "react";
import {
  useMediaQuery,
  ThemeProvider,
  createTheme,
  Grid,
  Typography,
  styled,
  Paper,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import CustomWidgetForHeader from "../../components/WebComponents/CustomTextForHeader";
import CustomWidgetForContent from "../../components/WebComponents/CustomTextForContent";
import ContentInLeftImageRightWeb from "../../components/WebComponents/ContentInLeftImageRightWeb";
import CustomWidgetForMobile from "../../components/MobComponents/CustomTextForMob";
import entrepreneurImage from "../../assets/Background/BecameAHost/entrepreneurs.png";
import convenientImage from "../../assets/Background/BecameAHost/convenient.png";
import scalableImage from "../../assets/Background/BecameAHost/scalable.png";
import flexibilityImage from "../../assets/Background/BecameAHost/flexbility.png";
import engagementImage from "../../assets/Background/BecameAHost/scalable.png";
import MobileStepper from "@mui/material/MobileStepper";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import SwipeableViews from "react-swipeable-views";
import AddVehicleImage from "../../assets/Background/BecameAHost/add_vehicle.png";
import HostPartnereImage from "../../assets/Background/BecameAHost/host_partner.png";
import CreateOwnImage from "../../assets/Background/BecameAHost/create_own.png";
import StationChoiceImage from "../../assets/Background/BecameAHost/station_choice.png";
import InsuranceOptionImage from "../../assets/Background/BecameAHost/insurance_option.png";
import EasyGuestImage from "../../assets/Background/BecameAHost/easy_guest.png";
import AchievetImage from "../../assets/Background/BecameAHost/achieve.png";
import ContentTopImageBelowMob from "../../components/MobComponents/ContentTopImageBelow";


const SwipeableViewsComponent = SwipeableViews;

const defaultTheme = createTheme();
const titleText = {
  title: "Become a host",
  description:
    "Odpo is offering you the opportunity to become a host and transform your vehicle into a source of income and excitement. Join our growing community of hosts who share their cars with travellers, while earning extra money and creating memorable experiences.",
};

const items = [
  {
    id: 1,
    textTitle: "Scalable",
    textSubtitle: "Decide how many cars you share with world",
    imageUrl: `${scalableImage}`,
  },
  {
    id: 2,
    textTitle: "Convenient",
    textSubtitle: "Manage reservations for your vehicles with ease",
    imageUrl: `${convenientImage}`,
  },
  {
    id: 3,
    textTitle: "Flexibility",
    textSubtitle: "Rent out your vehicle when it suits you best.",
    imageUrl: `${flexibilityImage}`,
  },
  {
    id: 4,
    textTitle: "Engagement",
    textSubtitle: "Connect with guests when needed",
    imageUrl: `${engagementImage}`,
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F6F6F6",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "200px",
  width: "200px",
  justifyContent: "space-between",
}));

const BecomeAHost = () => {
  const isMobileView = useMediaQuery("(max-width: 767px)");
  const customePadding = isMobileView ? "4%" : "8%";

  const theme = useTheme();
 


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  // const [activeStep, setActiveStep] = useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevStep) => prevStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevStep) => prevStep - 1);
  // };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const images = [
    {
      label: "Add vehicles Manually or Automatically",
      imgPath: AddVehicleImage,
    },
    {
      label: "Host-Partner Management",
      imgPath: HostPartnereImage,
    },
    {
      label: "Create own price plans",
      imgPath: CreateOwnImage,
    },
    {
      label: "Stations of choice",
      imgPath: StationChoiceImage,
    },
    {
      label: "Insurance options",
      imgPath: InsuranceOptionImage,
    },
    {
      label: "Easy guest communicationa",
      imgPath: EasyGuestImage,
    },
  ];
  const itemsPerPage = 2;
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  //const maxSteps = images.length;

  const maxSteps = Math.ceil(images.length / itemsPerPage);

  // const startIndex = activeStep * itemsPerPage;
  // const endIndex = Math.min(startIndex + itemsPerPage, images.length);
  // const displayedImages = images.slice(startIndex, endIndex);

  return (
    <ThemeProvider theme={defaultTheme}>
      <div style={{ padding: "4%" }}>
        <div
          id="Web_How_Odpo_works"
          style={{
            paddingLeft: customePadding,
            paddingRight: customePadding,
          }}
        >
         {isMobileView?
         <CustomWidgetForMobile
            title={titleText.title}
            subtitle1={titleText.description}
            titileFont={"2.5rem"}
            alignText="center"
          />: <CustomWidgetForHeader
            title={titleText.title}
            subtitle1={titleText.description}
            
          />}
        </div>
        <div id="Web_Left_Text_right_image">
          {/* <Grid
            container
            style={{
              paddingTop: "8%",
              paddingBottom: "4%",
              display: "flex",
              alignItems: "start",
              justifyItems: "center",
            }}
          >
            <Grid item xs={12} sm={6}>
              <div style={{ paddingRight: "4%" }}>
               
                <CustomWidgetForContent
                title={"Entrepreneur's Welcome"}
                subtitle1={"If you aspire to become an entrepreneur and pursue your dreams, you're warmly welcomed at Odpo. Whether you prefer starting small with just a couple of cars or even a single one, you can generate extra income, turning your dreams into reality."}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <img
                src={entrepreneurImage}
                alt="not loading"
                style={{ maxWidth: "100%", height: "auto" }}
                loading="lazy"
              />
            </Grid>
          </Grid> */}
          {isMobileView?
          <ContentTopImageBelowMob   title={"Entrepreneur's Welcome"}
          subtitle1={"If you aspire to become an entrepreneur and pursue your dreams, you're warmly welcomed at Odpo. Whether you prefer starting small with just a couple of cars or even a single one, you can generate extra income, turning your dreams into reality."}
        imgSource={entrepreneurImage}/>:
          <ContentInLeftImageRightWeb
            title={"Entrepreneur's Welcome"}
            subtitle1={"If you aspire to become an entrepreneur and pursue your dreams, you're warmly welcomed at Odpo. Whether you prefer starting small with just a couple of cars or even a single one, you can generate extra income, turning your dreams into reality."}
          imgSource={entrepreneurImage}
          
          />}
        </div>
      </div>

      <div id="Web_business_at_Odpo">
        <div style={{ backgroundColor: "#1F282D", width: "100%" ,}}>
          <div style={{ padding: "3%" }}>
          {isMobileView?
         <CustomWidgetForMobile
            title={titleText.title}
            subtitle1={titleText.description}
            titileFont={"2.5rem"}
            alignText="center"
          />:<CustomWidgetForContent
              title={"Business at Odpo"}
              subtitle1={
                "Odpo empowers you to effortlessly build and oversee a car fleet, providing a user-friendly experience for seamless control and management."
              }
              titleColor={"white"}
              subtitleColor={"white"}
            />}
            {isMobileView?
            <div
            style={{
              display: "flex",
              overflowX: "auto",
              width: "100%",
              padding: "10px",
            }}
          >
            {items.map((item, index) => (
             <div style={{paddingRight:"10%"}}>
               <img
              style={{ height: "250px", }}
              src={`${item.imageUrl}`}
              alt={`${item.id}`}
              loading="lazy"
            />
            <Typography
                    style={{ color: "white" }}
                    variant={"h6"}
                    component="div"
                    mt={2}
                  >
                    {item.textTitle}
                  </Typography>
                  <Typography
                    style={{ color: "white" }}
                    variant={"body1"}
                    component="div"
                    mt={1}
                  >
                    {item.textSubtitle}
                  </Typography>
              </div>
            ))}
          </div>
            :<Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 6 }}
              justifyContent={"flex-start"}
              padding={"2%"}
            >
              {items.map((item) => (
                <Grid key={item.id} item xs={6} sm={3}>
                  <Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                        height: "100%",
                      }}
                    >
                      <img
                        style={{ height: "200px" }}
                        src={`${item.imageUrl}`}
                        alt={`${item.id}`}
                        loading="lazy"
                      />
                    </div>
                  </Item>
                  <Typography
                    style={{ color: "white" }}
                    variant={"h5"}
                    component="div"
                    mt={2}
                  >
                    {item.textTitle}
                  </Typography>
                  <Typography
                    style={{ color: "white" }}
                    variant={"h6"}
                    component="div"
                    mt={1}
                  >
                    {item.textSubtitle}
                  </Typography>
                </Grid>
              ))}
            </Grid>}
          </div>
        </div>
      </div>

      <div id="Swipeable_with text">
        {/* TODO: swipeable image */}
        <Box
          sx={{
           
            display: "flex",
            width: "100%",
            paddingTop:"8%",
            paddingBottom:"8%"
          }}
        >
          {!isMobileView && (
            <IconButton
              sx={{
                flex: "initial",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <ArrowBackIosRoundedIcon sx={{ color: "#BEBABA" }} />
            </IconButton>
          )}

          <Box
            borderRadius={"4px"}
            sx={{
              background: "#F6F6F6",
              padding: "1.5rem",
            }}
          >
            <SwipeableViewsComponent
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              style={{ maxWidth: "620px" }}
            >
              {images.map((step, index) => (
                <div
                  key={step.label}
                  style={{
                    width: "100%",
                  }}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {images
                        .slice(
                          activeStep * itemsPerPage,
                          (activeStep + 1) * itemsPerPage
                        )
                        .map((item) => (
                          <div key={item.label}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                p:"auto"
                              }}
                            >
                              <img
                                alt={item.label}
                                src={item.imgPath}
                                style={{
                                  height: "50%",
                                }}
                              />
                              <Typography
                                variant="h6"
                                component="div"
                                gutterBottom
                              >
                                {item.label}
                              </Typography>
                            </Box>
                          </div>
                        ))}
                    </Box>
                  ) : null}
                </div>
              ))}
            </SwipeableViewsComponent>

            <MobileStepper
              variant="progress"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F6F6F6",
                marginTop: "2rem",
                "& .MuiMobileStepper-dotActive": {
                  backgroundColor: "#1F282D",
                },
              }}
            />
          </Box>
          {!isMobileView && (
            <IconButton
              sx={{
                flex: "initial",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <ArrowForwardIosRoundedIcon sx={{ color: "#BEBABA" }} />
            </IconButton>
          )}
        </Box>
      </div>

      {
        isMobileView?
        <div style={{ padding: "auto" }}>
          <div
            id="Mob_Achive_Text"
            style={{
              paddingLeft: customePadding,
              paddingRight: customePadding,
              paddingBottom:"9%"
            }}
          >
            
            <Typography
              style={{
                paddingBottom: "4%",
                fontSize: "1.5rem",
                textAlign: "left",
              }}
            >
              Achieve Transparent Earnings and Secure Insurance with Odpo
            </Typography>
            <Typography
              style={{ fontSize: "1rem", }}
              paragraph
            >
              Odpo is known for being open and honest. As a host on Odpo, you
              always know exactly how much you'll earn - no hidden surprises.
              What's more, Odpo believes in giving you the power to choose what
              suits you best. You have the option to add your own insurance if
              that's what you prefer - it's your decision entirely. This
              flexibility makes Odpo unique among others. Your earnings are clear,
              and you're in charge, making your experience as a host on Odpo
              transparent and empowering.
            </Typography>
            <img
              alt={"archive"}
              src={AchievetImage}
              style={{ height: "25vh", paddingRight:"2rem"}}
            />
          </div>
        </div>:
        <div style={{ padding: "auto" }}>
          <div
            id="Web_Achive_Text"
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom:"9%"
            }}
          >
            <CustomWidgetForContent
                title = { 'Achieve Transparent Earnings and Secure \n Insurance with Odpo'}
                textAline1={"center"}
                subtitle1={"Odpo is known for being open and honest. As a host on Odpo, you always know exactly how much you'll earn - no hidden surprises. What's more, Odpo believes in giving you the power to choose what suits you best. You have the option to add your own insurance if that's what you prefer - it's your decision entirely. This flexibility makes Odpo unique among others. Your earnings are clear, and you're in charge, making your experience as a host on Odpo transparent and empowering."}
                textAline2={"center"}
                />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
         <img
              alt={"archive"}
              src={AchievetImage}
              style={{ height: "100%",}}
            />
          </div>
          </div>
          
        </div>
      }
    </ThemeProvider>
  );
};

export default BecomeAHost;
