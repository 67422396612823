import { useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import"./../../../../index.css";
import CustomWidgetForHeader from "../../../../components/WebComponents/CustomTextForHeader";
import { menuHeight } from "../../../../assets/Utility/constant";
import ContentTitleWithLine from "../../../../components/ComponentUtilities/ContentTitleWithLine";
import CustomWidgetForMob from "../../../../components/MobComponents/CustomTextForMob";

const titleText = {
  title: "Vehicle Reservation",
};

const defaultTheme = createTheme();

const VehicleReservations = () => {
  useEffect(() => {
    const section = window.location.hash;

    // If a section is specified in the URL, scroll to it
    if (section) {
      const targetSection = document.querySelector(section);
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - menuHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)"); 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container style={{ maxWidth: "100%", padding: "4%" }}>
        {/* Vehicle Reservation */}
        <section id="VehicleReservation">
        { isMobile?<CustomWidgetForMob title={titleText.title}></CustomWidgetForMob>:<CustomWidgetForHeader title={titleText.title} />}

          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Vehicle Reservation" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  The initial step to reserve a vehicle is to confirm your
                  eligibility and ensure that your driving license has been
                  verified. You can verify this by checking the Driving License
                  Verification status under the Account Details Tab. Once your
                  verification is complete, you can proceed to reserve a
                  vehicle.
                </li>
                <li>
                  To initiate the reservation process, navigate to the Search
                  Rentals Tab. Update the Pickup Address, Date, Time, and
                  Drop-Off Address, Date & Time fields accordingly. Upon
                  entering these details, a list of available vehicles at the
                  designated pickup address will be displayed on the screen.
                  After choosing a vehicle and reviewing the associated price,
                  click on the "Reserve" button.
                </li>
                <li>
                  On the subsequent screen, you can make the payment using
                  either your existing card registered in the application or by
                  adding a new card.
                </li>
                <li>
                  Following a successful payment and vehicle reservation, you
                  will receive a notification via the In-App messaging system.
                  You can review your reserved vehicle(s) by accessing the
                  Reservations Tab.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>

        {/*  Existing Reservations */}
        <section id="ExistingReservations">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Existing Reservations" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  You can access all your reservations through the
                  “Reservations” tab in your “Odpo Drive” app.
                </li>

                <li>
                  You have the option to filter reservations based on their
                  different states.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>
        {/*  What does the various reservation states mean */}
        <section id="ReservationStates">
          <ContentTitleWithLine text="What does the various reservation states mean" />
          <Box paddingRight={2}>
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  "Ready to start" - The reservation can be initiated by you.
                </li>
                <li>
                  "Active" - The reservation has been started and is currently
                  in progress.
                </li>
                <li>"Upcoming" - These are future reservations.</li>
                <li>
                  "Abandoned" - The reservation has been started, meaning the
                  vehicle was not picked up at the scheduled time.
                </li>
                <li>"Canceled" - The reservation has been canceled.</li>
                <li>"Past/Completed" - The reservation has concluded.</li>
                <li>
                  "Running late" - The reservation has exceeded the drop-off
                  time and the allowed grace period, resulting in a delay. Late
                  fees may apply for reservations that are running late.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>
        {/* Locating Your Reserved Vehicle */}
        <section id="LocatingYourReserved">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Locating Your Reserved Vehicle" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  A vehicle gets associated with your reservation once your
                  reservation reaches a "ready to start" state, and the license
                  plate number of the vehicle is displayed alongside the
                  reservation.
                </li>
                <li>
                  To locate the vehicle associated with your reservation in the
                  "ready to start" or "active" state, use the "Locate Vehicle"
                  option within the Reservation Details. This will open the
                  default map application on your phone, guiding you to your
                  reserved vehicle.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>
        {/*How do I start a reservation */}
        <section id="StartReservation">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="How do I start a reservation" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>To begin a reservation, follow these steps:</p>
              <ol type="1">
                <li>Select a reservation with the status "Ready to Start".</li>
                <li>Click on "Start Reservation".</li>
                <li>You will be prompted to capture images of the vehicle.</li>
                <li>
                  After capturing all the required images, you can proceed to
                  start your reservation.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
        {/* Extending a Trip */}
        <section id="ExtendingTrip">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Extending a Trip" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                Duration of an active reservation can be extended from
                application. To extend, please follow below steps:
              </p>
              <ol type="1">
                <li>Select a reservation with the status "Active".</li>
                <li>
                  Locate the option “Extend Reservation“ and update date and
                  time in next prompted screen.
                </li>
                <li>Extend will be auto-approved by system.</li>
              </ol>
            </Typography>
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>However, there are two different scenarios:</p>
              <ul>
                <li>
                  <b>Extension Approval - When Vehicle Is Available: &nbsp;</b>If the vehicle you wish to extend your reservation for is not
                  reserved by another guest for the requested date and time, our
                  system will automatically approve your extension.
                </li>

                <li>
                  <b>
                    Extension Availability Based on Subsequent
                    Reservation:&nbsp;</b>If the vehicle has already been reserved by another guest for
                  the date and time you are looking to extend, you will receive
                  a pop-up notification. This notification will inform you that
                  the extension is available only until the XYZ date and ZZ
                  time. The time mentioned will be set at 30 minutes before the
                  start of the subsequent guest's reservation.
                </li>
              </ul>
            </Typography>
          </Box>
        </section>

        {/*Reporting Incidents*/}
        <section id="ReportingIncidents">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Reporting Incidents" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <ul>
                <li>
                  Incidents are a means for you to report any issues encountered
                  with a reservation. This includes concerns about the vehicle"s
                  condition upon receipt, accidents, or any problems faced
                  during your reservation period. To report an incident related
                  to a reservation, utilize the "Report Incident" option found
                  within the Reservation Details. Provide a comprehensive
                  description of the issue you"re facing and include images of
                  the vehicle if it has been affected by the incident. In
                  addition to a written report, you have the option to submit
                  images for a more detailed account.
                </li>
                <li>
                  Please note that you are only allowed to report an incident
                  for a reservation when it is in a "ready to start" or "active"
                  state.
                </li>
              </ul>
            </Typography>
            <Typography sx={{ fontSize:isMobile?"0.875rem":"1.2rem"}}> 
             <p>
             Note: Currently, only the "Call for Support" option is available.
              The Chat feature will be updated in a future release. 
              </p> 
            </Typography>
          </Box>
        </section>

        {/*ViewingInsurance Card*/}
        <section id="ViewingInsurance">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Viewing Insurance Card" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
                <p>
                You can easily access your insurance card for reservations.
                Please note that the card can be viewed only for reservations
                with the status “Upcoming” or “Ready-to-Start”.
              </p>
              <p>Follow these steps under the Reservations tab:</p>
              <ol type="1">
                <li>Navigate to the Reservations tab.</li>
                <li>Select the reservation you're interested in.</li>
                <li>
                  Click on the "Insurance Details" option located above the
                  community guidelines.
                </li>
                <li>On the next screen, select "Insurance Card."</li>
                <li>
                  The insurance card details will be displayed on the next
                  screen for your reference.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>

        {/*Ending a Reservation*/}
        <section id="EndingReservation">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Ending a Reservation" />

            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem" }}
            >
              <p>
                After Returning the vehicle to the drop-off address, follow
                below steps to end a Reservation.
              </p>
              <ol type="1" style={{ lineHeight: 2 }}>
                <li>Select the reservation you wish to End.</li>
                <li>
                  At bottom of screen, locate “Initiate End Reservation” option.
                </li>
                <li>
                  You will then be prompted to confirm that you have exited the
                  vehicle.
                </li>
                <li>
                  Post confirming, you will be asked to capture images of the
                  vehicle to complete the End reservation process.
                </li>
              </ol>
            </Typography>
          </Box>
        </section>
        {/*Abandoned*/}
        <section id="Abandoned">
          <Box mt={4} mb={2}>
            <ContentTitleWithLine text="Abandoned" />
            <Typography
              sx={{ fontSize:isMobile?"body2":"1.5rem"}}
            >
              <p>
                If for any reason you are delayed in reaching the vehicle at the
                designated time, our application will send a reminder
                notification to both you and the host. However, failing to
                arrive on time will result in a Abandoned status. In such cases,
                the reservation amount will be deducted from your account. It's
                essential to prioritize punctuality and be responsible for
                timely collection of your reserved vehicle.
              </p>
            </Typography>
          </Box>
        </section>
      </Container>
    </ThemeProvider>
  );
};

export default VehicleReservations;
