import React from "react";
import { Typography, ThemeProvider, createTheme,Grid } from "@mui/material";

// Define a custom theme
const theme = createTheme();

const ContentTopImageBelowMob = ({ title, subtitle1, subtitle2,customAlignText,paragraphAline,imgSource }) => {

  return (
    <div >
      <ThemeProvider theme={theme}>
        {/* <Typography fontSize={'1.5rem' } fontWeight={"600"} mt={4} align={customAlignText}  >
          {title}
        </Typography>
        {subtitle1 && (
          <Typography fontSize={'1rem'} mt={2} align={customAlignText} paragraph={false||paragraphAline}>
            {subtitle1}
          </Typography>
        )}
        {subtitle2 && (
          <Typography fontSize={".875rem"} mt={2} align={customAlignText} paragraph={false||paragraphAline}>
            {subtitle2}
          </Typography>
        )} */}
        <Grid
    container
    style={{
      paddingTop: "8%",
      paddingBottom: "4%",
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
      flexDirection:"column-reverse"
    }}
  >
    <Grid item xs={12} sm={6}>
      <div style={{ paddingRight: "4%",paddingBottom: "8%" }}>
      <Typography fontSize={'1.5rem' } fontWeight={"600"} mt={4} align={customAlignText}  >
          {title}
        </Typography>
        {subtitle1 && (
          <Typography fontSize={'1rem'} mt={2} align={customAlignText} paragraph={false||paragraphAline}>
            {subtitle1}
          </Typography>
        )}
        {subtitle2 && (
          <Typography fontSize={".875rem"} mt={2} align={customAlignText} paragraph={false||paragraphAline}>
            {subtitle2}
          </Typography>
        )} 
      </div>
    </Grid>

    <Grid item xs={12} sm={6}>
      <img
        src={imgSource}
        alt="not loading"
        style={{ maxWidth: "100%", height: "auto" }}
        loading="lazy"
      />
    </Grid>
  </Grid>

      </ThemeProvider>
    </div>
  );
};

export default ContentTopImageBelowMob;
